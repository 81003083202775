import ko from 'knockout';
import { GroupMemberDto } from '../../../Models/groupManagement/groupMemberDto';

import template from './groups.html';

export function GroupsViewModel(routeParams) {

  const self = this;
  self.allGroups = ko.observableArray(routeParams.allGroups);
  self.totalCount = ko.pureComputed(() => self.allGroups().length);
  self.groupDetailsValidationRules = routeParams.validationRules;

  self.selectedItemId = ko.observable(null);
  self.isItemSelected = ko.pureComputed(() => self.selectedItemId() !== null);

  // Updates a the group members for an item
  self.updateItem = itemSummaryDto => {
    const items = self.allGroups();
    const itemToUpdate = items.find(x => x.groupId === itemSummaryDto.groupId);
    const newGroupMembers = itemSummaryDto.assignedGroupMembers
        .map(x => new GroupMemberDto(x.memberPersona.personaId, x.memberPersona.personaDisplayName, x.isGroupPrimaryContact));
    itemToUpdate.groupMembers(newGroupMembers);
  };

  // Is used for updating a group summary list when single group is removed
  self.deleteItem = itemId => {
    const items = self.allGroups();

    const deleteIndex = items.findIndex(x => x.groupId === itemId);
    items.splice(deleteIndex, 1);

    self.allGroups(items);
    self.selectedItemId(null);
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: GroupsViewModel, template: template };
