import ko from 'knockout';
import logger from '../../Utils/logger';

/**
 * Helper to always retieve accessors and binding values as observables. If a values was orignally a
 * raw value then it's wrapped in a new observable.
 */
function MapObservableHelper() {

  const self = this;

  /**
   * @param {function} valueAccessor
   * @param {*} [defaultValue]
   * @return {*}
   */
  self.getAccessorObservable = function (valueAccessor, defaultValue) {

    const value = valueAccessor();
    return getObservable(value, defaultValue);
  };

  self.getBindingObservable = function (name, allBindings, defaultValue) {

    const value = allBindings.get(name);
    return getObservable(value, defaultValue);
  };

  /**
   *
   * @param {*} value
   * @param {*} [defaultValue]
   * @return {*}
   */
  function getObservable(value, defaultValue) {

    if (ko.isObservable(value)) {
      return value;
    }

    if (!isUndefinedOrNull(value)) {
      return ko.observable(ko.unwrap(value));
    }

    if (!isUndefinedOrNull(defaultValue)) {
      return ko.observable(defaultValue);
    }

    logger.error('UnhandledError', 'Neither a value or a default value have been provided.');
  }

  function isUndefinedOrNull(value) {

    if (typeof value === 'undefined' || value === null) {
      return true;
    }

    return false;
  }
}

export default new MapObservableHelper();
