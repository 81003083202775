import * as ko from 'knockout';
import { Observable } from 'knockout';
import DualListBoxIconDetails from './DualListBoxIconDetails';

class DualListBoxItem {
  public isChecked: Observable<boolean>;
  public isSelected: Observable<boolean>;

  // this empty function is workaround to handle bubbling to distinguish clicks
  // on parent container from clicks on the checkbox
  public emptyClickBubbleHandler = (): true => {
    return true;
  };

  constructor(public id: string, public name: string, isChecked: boolean, public iconDetails: DualListBoxIconDetails | null = null) {
    this.isChecked = ko.observable(isChecked);
    this.isSelected = ko.observable(false);
  }
}
export default DualListBoxItem;
