import ko from 'knockout';
import template from './pagination.html';

/**
 * Component to create a pagination control.
 *
 * @example <pagination params="currentPage: currentPage, pageSize: pageSize, total: total"></pagination>
 * @param {Object} params
 */
export function PaginationViewModel(params) {

  const self = this;

  if (!ko.isObservable(params.currentPage)) {
    throw new Error('The required parameter currentPage must be an observable');
  }

  if (!ko.isObservable(params.pageSize)) {
    throw new Error('The required parameter pageSize must be an observable');
  }

  if (!ko.isObservable(params.total)) {
    throw new Error('The required parameter total must be an observable');
  }

  self.currentPage = params.currentPage;
  self.pageSize = params.pageSize;
  self.total = params.total;

  // this case can happen when user open first and only element on page > 1 then go back to caseList
  // that meen case no more in new tab and page is empty
  function redirectIfEmptyPage(total, pageSize) {
    const currentPage = self.currentPage();
    const dataLoaded = self.total() ? true : false;
    if (dataLoaded && currentPage > 1) {
      const newCurrentPage = Math.ceil(total / pageSize);
      if (newCurrentPage < currentPage) {
        self.currentPage(newCurrentPage);
      }
    }
  }

  self.totalPages = ko.pureComputed(function () {

    // If the total and page size aren't set yet as during initial render before observables bound then
    // default to a single page of results.
    const total = self.total() || 1;
    const pageSize = self.pageSize() || 1;
    redirectIfEmptyPage(total, pageSize);

    return Math.max(Math.ceil(total / pageSize), 1);
  });

  self.pages = ko.pureComputed(function () {

    const pages = [];
    const lastPage = self.totalPages();
    let i;
    let isActive;

    for (i = 1; i <= lastPage; i++) {
      isActive = i === self.currentPage();
      pages.push(new PageItemModel(i, isActive));
    }

    return pages;
  });

  self.changePage = function (pageItem) {
    self.currentPage(pageItem.number);
  };
}

/**
 * @param {number} number The page number
 * @param {bool} isActive
 */
function PageItemModel(number, isActive) {

  const self = this;

  self.text = number.toString();
  self.number = number;
  self.isActive = isActive;
}

// The default export returns the component details object to register with KO
export default { viewModel: PaginationViewModel, template: template };


