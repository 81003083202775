import * as ko from 'knockout';
import { Observable } from 'knockout';
import resourceHelper from '@/Utils/resourceHelper';
import template from './cctvUploadConfirmationModal.html';

class CctvUploadConfirmationModalViewModel {
  isVisible!: Observable<boolean>;

  public filesAndFoldersTitle: string;

  public confirmationError: Observable<boolean>;

  public isCctvUploadConfirmed: Observable<boolean>;

  public executeAction = (): void => {
    if (this.isCctvUploadConfirmed()) {
      this.processCctvFolderUploadCallback();
      this.isCctvUploadConfirmed(false);
      this.confirmationError(false);
      return;
    }
    this.confirmationError(true);
  };

  private processCctvFolderUploadCallback: () => any;

  constructor(params: any) {
    this.isVisible = params.isVisible;
    this.processCctvFolderUploadCallback = params.processCctvFolderUploadCallback;
    this.confirmationError = ko.observable(false);
    this.isCctvUploadConfirmed = ko.observable(false);
    this.filesAndFoldersTitle = resourceHelper.getString('FilesAndFoldersTitle');

    this.isVisible.subscribe((value: boolean) => {
      if (!value) {
        this.confirmationError(false);
      }
    });

    this.isCctvUploadConfirmed.subscribe((value: boolean) => {
      if (value) {
        this.confirmationError(false);
      }
    });
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: CctvUploadConfirmationModalViewModel, template: template };
