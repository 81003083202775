import ko from 'knockout';
import logger from '../Utils/logger';
import 'knockout.validation';

/**
 * Applies validation rules to a view model based on rules and localised error messages JSON previously
 * returned from the server. Uses the Knockout-Validation KnockoutJS Plugin.
 * https://github.com/Knockout-Contrib/Knockout-Validation
 * @param {Object} viewModel
* @constructor
 */
function ValidationRulesService(viewModel) {

  const self = this;

  // Contains all the validation rule messages to then expose them via the getValidationMessage function
  const validationRuleMessages = {};

  /**
   * Apply the validation rules for the view model to the observables
   * @param {Object} validationRules
   */
  self.applyValidation = function (validationRules) {

    let property;
    let rules;
    let observable;
    let i;

    if (!validationRules) {
      logger.warning('UnhandledError', 'The validation rules object is not populated');
    }

    for (property in validationRules) {

      if (!Object.prototype.hasOwnProperty.call(validationRules, property)) {
        continue;
      }

      rules = validationRules[property];
      observable = viewModel[property];

      if (!observable) {

        logger.warning(
            'UnhandledError',
            'The validation rules object contains validation for the observable property \'' +
          property + '\' which wasn\'t found on the view model');

        continue;
      }

      for (i = 0; i < rules.length; i++) {
        applyValidationRule(rules[i], property, viewModel);
        populateValidationRuleMessages(validationRuleMessages, rules[i], property);
      }
    }
  };

  /**
   * Gets the validation message for the given property and validation type
   * @param {string} propertyName
   * @param {string} validationRuleType
   * @return {string}
   */
  self.getValidationMessage = function (propertyName, validationRuleType) {

    const propertyObject = validationRuleMessages[propertyName];

    if (!propertyName) {
      return null;
    }

    const message = propertyObject[validationRuleType];

    return message || null;
  };

  /**
   * Calls extend on the observable and passes in the knockout validation object settings
   * @private
   * @param {Object} rule
   * @param {String} property
   * @param {Object} viewModel
   */
  function applyValidationRule(rule, property, viewModel) {

    let otherObservable;

    // This is a little crude but is the only scenario currently where we need to swap a string params name
    // with the real view model observable. If this is needed in other cases then do something more generic.
    if (rule.equal) {

      // If the param property already references an observable then this routine has already been run
      // against this validation rules object so nothing to do here.
      if (ko.isObservable(rule.equal.params)) {
        return;
      }

      otherObservable = viewModel[rule.equal.params];

      if (!otherObservable) {
        logger.warning('The validation rule \'equal\' refers the observable property \'' +
          rule.equal.params + '\' which wasn\'t found on the view model');

        return;
      }

      // Swap the string observable property name with a reference to the real observable
      rule.equal.params = otherObservable;
    }

    viewModel[property].extend(rule); // Call extend on the observable
  }

  /**
   * Populate the validationRuleMessages with a new validation message. The validationRuleMessages object
   * and this function only exist to expose translated validation messages outside of this module.
   * The format of the object is:
   * validationRuleMessages.{property}.{ruleTypeProperty} = '{message}'
   * validationRuleMessages.givenName.required = 'Please enter your first name'
   * @private
   * @param {Object} validationRuleMessages
   * @param {Object} rule
   * @param {String} property
   */
  function populateValidationRuleMessages(validationRuleMessages, rule, property) {

    const ruleTypeProperty = Object.keys(rule)[0]; // Single property per rule (e.g. required, maxLength)
    validationRuleMessages[property] = validationRuleMessages[property] || {};
    validationRuleMessages[property][ruleTypeProperty] = rule[ruleTypeProperty].message;
  }
}

export default ValidationRulesService;
