import * as ko from 'knockout';
import { ObservableArray } from 'knockout';
import localStorageHelper, { LocalStorageKeyType } from '@/Utils/localStorageHelper';
import { FilterItemDto } from './filterItemDto';
import { FilterItemModel } from './filterItemModel';
import { FilterItemValueModel } from './filterItemValueModel';
import { FilterItemValueDto } from './filterItemValueDto';
import { InitialFilterCriteriaDto } from './initialFilterCriteriaDto';
import { FilterContentType } from '@/Types/Enums/filterContentType';

export class FilterModel {
  public filterItems: ObservableArray<FilterItemModel>;
  private readonly defaultFilterCriteriaItems: FilterItemModel[];
  private readonly externalValueChangeHandler: () => void;
  private readonly page: string;
  private selectedFilterIsAvailable: boolean;

  constructor(externalValueChangeHandler: () => void, page: string, initialFilter: InitialFilterCriteriaDto) {
    this.filterItems = ko.observableArray();
    this.page = page;
    this.externalValueChangeHandler = externalValueChangeHandler;
    this.selectedFilterIsAvailable = false;
    this.defaultFilterCriteriaItems = this.mapItemsToFilterCriteria(initialFilter.defaultFilterCriteria, this.filterValueChangeHandler);
    const initialFilterItems = this.mapItemsToFilterCriteria(initialFilter.initialFilterCriteria, this.filterValueChangeHandler);
    const persistedFilterCriteriaItems = this.getPersistedFilterCriteriaFromStorage(this.page, this.filterValueChangeHandler);
    if (persistedFilterCriteriaItems.length) {
      this.syncItemValues(initialFilterItems, persistedFilterCriteriaItems);
      this.selectedFilterIsAvailable = true;
    }
    this.filterItems(initialFilterItems);
  }

  public filterValueChangeHandler = (): void => {
    this.saveToLocalStorage();
    // calls the filter host's handler
    this.externalValueChangeHandler();
  };

  public updateFilterCriteria = (filterItems: FilterItemDto[]): void => {
    this.mergeReceivedItems(this.filterItems(), filterItems);
    this.selectedFilterIsAvailable = true;
  };

  public applyDefaultFilterCriteria = (): void => {
    this.resetFilter();
    this.syncItemValues(this.filterItems(), this.defaultFilterCriteriaItems);
    this.saveToLocalStorage();
    this.selectedFilterIsAvailable = true;
  };

  public getSelectedFilterCriteria = (): FilterItemDto[] => {
    if (!this.selectedFilterIsAvailable) {
      this.applyDefaultFilterCriteria();
    }

    const selectedFilterCriteriaItems = this.filterItems().reduce((accFilterItems, filterItem) => {
      if (filterItem.type === FilterContentType.text) {
        const filterItemDto = new FilterItemDto({
          name: filterItem.name,
          values: filterItem.values()
        } as unknown as FilterItemDto);
        accFilterItems.push(filterItemDto);
      } else {
        const selectedItemValues = this.getSelectedItemValues(filterItem);
        if (selectedItemValues.length > 0) {
          const filterItemDto = new FilterItemDto({
            name: filterItem.name,
            values: selectedItemValues
          } as FilterItemDto);
          accFilterItems.push(filterItemDto);
        }
      }
      return accFilterItems;
    }, new Array<FilterItemDto>());

    return selectedFilterCriteriaItems;
  };

  public resetFilter = (): void => {
    this.filterItems().forEach(item => item.resetCheckedValues());
  };

  private saveToLocalStorage = (): void => {
    const key = this.getLocalStorageKeyByPage(this.page);
    if (key !== null) {
      localStorageHelper.setAuthUserSessionValue(key, ko.toJSON(this.filterItems()));
    }
  };

  private getPersistedFilterCriteriaFromStorage(page: string, filterValueChangeHandler: () => void): FilterItemModel[] {
    const key = this.getLocalStorageKeyByPage(page);
    const persistedFilterCriteria = key !== null ? localStorageHelper.getAuthUserSessionValue(key) : null;

    if (!persistedFilterCriteria) {
      return new Array<FilterItemModel>();
    }
    const serializedItems = JSON.parse(persistedFilterCriteria);
    return this.mapItemsToFilterCriteria(serializedItems, filterValueChangeHandler);
  }

  private mapItemsToFilterCriteria =
    (filterItems: any, filterValueChangeHandler: () => void): FilterItemModel[] => {
      if (!Array.isArray(filterItems) || !filterItems.length) {
        return new Array<FilterItemModel>();
      }
      const items = filterItems.map((item: any): FilterItemModel => {
        const isExpandedFilter = true;
        return new FilterItemModel(item, isExpandedFilter, filterValueChangeHandler);
      });
      items.sort((a, b) => (a.position - b.position));
      return items;
    };

  private syncItemValues(itemsToUpdate: FilterItemModel[], selectedItems: FilterItemModel[]): void {
    itemsToUpdate.forEach(itemToUpdate => {
      const selectedItem = selectedItems.find(selectedItem => {
        return itemToUpdate.name === selectedItem.name;
      });
      if (selectedItem) {
        if (selectedItem.componentName === 'text-filter') {
          itemToUpdate.syncTextValues(selectedItem.values());
        } else {
          itemToUpdate.syncCheckedValues(selectedItem.values());
        }
      }
    });
  }

  private mergeReceivedItems = (existedItems: FilterItemModel[], receivedItems: FilterItemDto[]): void => {
    existedItems.forEach((existedItem: FilterItemModel) => {
      const receivedItem = receivedItems.find(receivedItem => {
        return receivedItem.name === existedItem.name;
      });
      if (receivedItem) {
        existedItem.mergeValues(existedItem.values(), receivedItem.values, this.filterValueChangeHandler);
      }
    });
  };

  private getLocalStorageKeyByPage = (page: string): LocalStorageKeyType | null => {
    // it is only one page for now with filter support
    if (page === 'requests') {
      return LocalStorageKeyType.RequestsFilterCriteria;
    }
    return null;
  };

  private getSelectedItemValues(filterItem: FilterItemModel): FilterItemValueDto[] {

    const selectedItemValues = filterItem.values().reduce(
      (accFilterItemValues: FilterItemValueDto[], filterItemValue: FilterItemValueModel): FilterItemValueDto[] => {
        if (filterItemValue.getLastCheckedValue()) {
          const filterItemValueDto = new FilterItemValueDto(
            filterItemValue.value,
            filterItemValue.title,
            filterItemValue.titleKey,
            filterItemValue.type,
            filterItemValue.getLastCheckedValue(),
            filterItemValue.counter(),
            filterItemValue.position);
          accFilterItemValues.push(filterItemValueDto);
        }
        return accFilterItemValues;
      }, new Array<FilterItemValueDto>());

    return selectedItemValues;
  }
}
