import * as ko from 'knockout';
import { ObservableArray, PureComputed, Observable } from 'knockout';
import resourceHelper from '../../Utils/resourceHelper';
import { DualListBoxOptions } from './types/dualListBoxOptions';
import DualListBoxItem from './types/DualListBoxItem';
import template from './dualListBox.html';
import { DualListBoxHeaderSize } from './types/DualListBoxHeaderSize';
import FilterDataModel from '../../Models/filterDataModel';

class DualListBox {
  public areaTitleKey: string;
  public availableItemsTitleString: string;
  public assignedItemsTitleString: string;
  public availableItemsTitleKey: string;
  public assignedItemsTitleKey: string;
  public checkedItemTooltipKey: string;
  public filterAreaTitleKey: string;
  public resAriaLabelAddKey: string;
  public resAriaLabelRemoveKey: string;
  public resAriaLabelAddAllKey: string;
  public resAriaLabelRemoveAllKey: string;
  public resAriaLabelCheckedItemKey: string;
  public availableItems: ObservableArray<DualListBoxItem>;
  public assignedItems: ObservableArray<DualListBoxItem>;
  public availableSelectedItems: ObservableArray<DualListBoxItem>;
  public assignedSelectedItems: ObservableArray<DualListBoxItem>;
  public headersSize: DualListBoxHeaderSize;
  public filteredItemIds: ObservableArray<string>;
  public itemsForFilter: ObservableArray;
  public filterCriteriaValue: Observable<string>;
  private isDataAvailable: Observable<boolean>;

  public displayedAvailableItems: PureComputed<DualListBoxItem[]> = ko.pureComputed(() => {
    return this.availableItems().filter(item => this.filterForItemFiltered(item));
  });

  public displayedAssignedItems: PureComputed<DualListBoxItem[]> = ko.pureComputed(() => {
    return this.assignedItems().filter(item => this.filterForItemFiltered(item));
  });
  public isAddButtonEnabled: PureComputed<boolean> = ko.pureComputed(() => {
    return this.availableSelectedItems().length > 0;
  });
  public isRemoveButtonEnabled: PureComputed<boolean> = ko.pureComputed(() => {
    return this.assignedSelectedItems().length > 0;
  });
  public isRemoveAllButtonEnabled: PureComputed<boolean> = ko.pureComputed(() => {
    return this.displayedAssignedItems().length > 0;
  });
  public isAddAllButtonEnabled: PureComputed<boolean> = ko.pureComputed(() => {
    return this.displayedAvailableItems().length > 0;
  });

  public availableItemsTitle: PureComputed<string> =
    ko.pureComputed(() => `${this.availableItemsTitleString} (${this.displayedAvailableItems().length})`);

  public assignedItemsTitle: PureComputed<string> =
    ko.pureComputed(() => `${this.assignedItemsTitleString} (${this.displayedAssignedItems().length})`);

  private mapForFilter = (i: DualListBoxItem): typeof FilterDataModel => {
    return new FilterDataModel(i.id, i.name);
  };

  private filterForItemFiltered = (u: DualListBoxItem): boolean => {
    return this.filteredItemIds().includes(u.id);
  };

  private getItemsForFilter = (): any[] => {
    const availableItems = this.availableItems().map(i => this.mapForFilter(i));
    const assignedItems = this.assignedItems().map(i => this.mapForFilter(i));
    return availableItems.concat(assignedItems);
  };

  constructor(private readonly options: DualListBoxOptions) {

    this.availableItems = options.availableItems;
    this.assignedItems = options.assignedItems;
    this.headersSize = options.headersSize;
    this.filteredItemIds = ko.observableArray(this.getResolvedFilterIds());
    this.itemsForFilter = ko.observableArray(this.getItemsForFilter());
    this.isDataAvailable = options.isDataAvailable.extend({ notify: 'always' });
    this.filterCriteriaValue = ko.observable('');

    this.isDataAvailable.subscribe((value: boolean): void => {
      if (!value) {
        return;
      }
      this.filteredItemIds(this.getResolvedFilterIds());
      this.itemsForFilter(this.getItemsForFilter());
      this.filterCriteriaValue('');
    });

    this.filteredItemIds.subscribe(() => {
      this.availableSelectedItems([]);
      this.assignedSelectedItems([]);
    });

    this.availableItemsTitleString = resourceHelper.getString(options.localizedStringKeys.AvailableItemsTitleKey);
    this.assignedItemsTitleString = resourceHelper.getString(options.localizedStringKeys.AssignedItemsTitleKey);

    this.areaTitleKey = options.localizedStringKeys.AreaTitleKey;
    this.availableItemsTitleKey = options.localizedStringKeys.AvailableItemsTitleKey;
    this.assignedItemsTitleKey = options.localizedStringKeys.AssignedItemsTitleKey;
    this.checkedItemTooltipKey = options.localizedStringKeys.CheckedItemTooltipKey;
    this.resAriaLabelCheckedItemKey = options.localizedStringKeys.ResAriaLabelCheckedItemKey;
    this.filterAreaTitleKey = options.localizedStringKeys.FilterAreaTitleKey;
    this.resAriaLabelAddKey = options.localizedStringKeys.ResAriaLabelAddKey;
    this.resAriaLabelRemoveKey = options.localizedStringKeys.ResAriaLabelRemoveKey;
    this.resAriaLabelAddAllKey = options.localizedStringKeys.ResAriaLabelAddAllKey;
    this.resAriaLabelRemoveAllKey = options.localizedStringKeys.ResAriaLabelRemoveAllKey;

    // Sort
    this.availableItems.sort(this.sortItems);
    this.assignedItems.sort(this.sortItems);

    this.availableSelectedItems = ko.observableArray();
    this.assignedSelectedItems = ko.observableArray();
  }

  public addAllAvailable = (): void => {
    this.addAll(this.availableSelectedItems, this.displayedAvailableItems, this.addAssigned);
  };

  public removeAllAssigned = (): void => {
    this.addAll(this.assignedSelectedItems, this.displayedAssignedItems, this.removeAssigned);
  };

  private getResolvedFilterIds = (): string[] => {
    const availableItems = this.availableItems().map<string>(i => i.id);
    const assignedItems = this.assignedItems().map<string>(i => i.id);
    return availableItems.concat(assignedItems);
  };

  private addAll = (
    selectedItems: ObservableArray<DualListBoxItem>,
    items: PureComputed<DualListBoxItem[]>,
    callback: () => void
  ): void => {
    selectedItems([]);
    ko.utils.arrayPushAll(selectedItems, items());
    callback();
  };

  public addAssigned = (): void => {
    this.moveItems(this.availableItems, this.availableSelectedItems, this.assignedItems, this.assignedSelectedItems);
  };

  public removeAssigned = (): void => {
    this.moveItems(this.assignedItems, this.assignedSelectedItems, this.availableItems, this.availableSelectedItems);
  };

  private moveItems = (
    sourceItems: ObservableArray<DualListBoxItem>,
    sourceSelectedItems: ObservableArray<DualListBoxItem>,
    destinationItems: ObservableArray<DualListBoxItem>,
    destinationSelectedItems: ObservableArray<DualListBoxItem>
  ): void => {
    destinationSelectedItems.removeAll();
    sourceSelectedItems().forEach(item =>{
      item.isSelected(false);
      item.isChecked(false);
      destinationItems.push(item);
    });
    destinationItems.sort(this.sortItems);
    sourceItems.removeAll(sourceSelectedItems());
    sourceSelectedItems.splice(0, sourceSelectedItems().length);
  };

  public selectAvailableItem = (value: DualListBoxItem): void => {
    this.selectItem(value, this.availableSelectedItems);
  };

  public selectAssignedItem = (value: DualListBoxItem): void =>{
    this.selectItem(value, this.assignedSelectedItems);
  };

  private selectItem = (
    value: DualListBoxItem,
    selectedItems: ObservableArray<DualListBoxItem>
  ): void => {
    const found = selectedItems().find(item => item.id === value.id);
    if (!found) {
      value.isSelected(true);
      selectedItems.push(value);
      return;
    }
    value.isSelected(false);
    selectedItems.remove(value);
  };

  private sortItems = (left: DualListBoxItem, right: DualListBoxItem): number => {
    return left.name === right.name ? 0 : (left.name < right.name ? -1 : 1);
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: DualListBox, template: template };
