import * as ko from 'knockout';
import { Observable, PureComputed } from 'knockout';

export class FilterItemValueModel {
  public value: string;
  public title: string;
  public titleKey: string;
  public type: string;
  public isChecked: Observable<boolean>;
  private lastCheckedValue: boolean;
  private isDisabled: boolean;
  public counter: Observable<number>;
  public position: number;
  public formattedCounter: Observable<string>;
  public initialValue: Observable<string> = ko.observable('');
  public currentValue: Observable<string> = ko.observable('');
  public externalValueChangeHandler: () => void;

  constructor(item: any, filterValueChangeHandler: () => void) {
    this.value = item.value;
    this.title = item.title;
    this.titleKey = item.titleKey;
    this.type = item.type;
    this.isChecked = ko.observable(item.isChecked || false);
    this.isDisabled = false;
    this.position = item.position;
    this.lastCheckedValue = this.isChecked();
    this.counter = ko.observable(0);
    this.formattedCounter = ko.observable('');
    this.setCounter(item.counter);
    this.isChecked.subscribe((newValue: boolean): void => {
      this.lastCheckedValue = newValue;
      if (!this.isDisabled) {
        filterValueChangeHandler();
      }
    });
    this.initialValue(this.value);
    this.currentValue(this.value);
    this.externalValueChangeHandler = filterValueChangeHandler;
  }

  public updateCheckedValueSilently = (value: boolean): void => {
    this.isDisabled = true;
    this.isChecked(value);
    this.isDisabled = false;
  };

  public updateTextValueSilently = (value: string): void => {
    this.isDisabled = true;
    this.currentValue(value);
    this.initialValue(value);
    this.value = value;
    this.isDisabled = false;
  };

  public getLastCheckedValue = (): boolean => {
    return this.lastCheckedValue;
  };

  public resetChecked = (): void => {
    this.isDisabled = true;
    this.isChecked(false);
    this.isDisabled = false;
  };

  public resetCounter = (): void => {
    this.setCounter(0);
  };

  public formattedItem: PureComputed<string> = ko.pureComputed((): string => {
    return `${this.title} ${this.formattedCounter()}`;
  });

  public setCounter = (counter: number): void => {
    this.counter(counter);
    this.formattedCounter(this.formatCounter(counter));
  };

  private formatCounter = (counter: number): string => {
    return counter > 0 ? ` (${counter})` : '';
  };

  public isDirty = ko.computed((): boolean => {
    const hasCurrentValue = this.currentValue();
    if (this.value === undefined) {
      if (hasCurrentValue) {
        return true;
      }
      return false;
    }
    return this.initialValue() !== this.currentValue();
  });

  public applyFilter = (): void => {
    this.value = this.currentValue();
    this.externalValueChangeHandler();
    this.resetIsDirty();
  };

  public resetIsDirty = (): void => {
    this.initialValue(this.value);
  };
}
