/*
 Selectize is the hybrid of a textbox and <select> box.
 It's jQuery based and it has autocomplete and native-feeling keyboard navigation; useful for tagging, contact lists, etc.
 Also it supports a remote data source.
 https://github.com/selectize/selectize.js
 https://github.com/selectize/selectize.js/blob/master/docs/usage.md

 The selectize Bootstrap CSS file is included in /Sass/bootstrap-custom.scss
*/
import $ from 'jquery';
import ko from 'knockout';
import logger from '../Utils/logger';
import resourceHelper from '../Utils/resourceHelper';
import 'selectize';

export const editableSelect = {

  init: function (element, valueAccessor, allBindings) {
    const value = valueAccessor();
    if (!ko.isObservable(value)) {
      logger.error(
          'UnhandledError',
          'The editable select binding value must be an observable for holding the selected item.');
    }
    const selectedItem = value();

    const options = Array.isArray(allBindings.get('options')) ?
      allBindings.get('options') :
      [];

    const getOptionsCallback = typeof(allBindings.get('options')) === 'function' ?
      allBindings.get('options') :
      null;

    const placeholder = typeof(allBindings.get('placeholderResourceKey')) === 'string' ?
      resourceHelper.getString(allBindings.get('placeholderResourceKey')) :
      '';

    const isDisabled = typeof (allBindings.get('disable')) === 'boolean' ?
      allBindings.get('disable') :
      false;

    const create = typeof (allBindings.get('create')) === 'function' ?
      allBindings.get('create') :
      typeof (allBindings.get('create')) === 'boolean' ?
        allBindings.get('create') ?
          (input, callback) => {
            const option = {
              added: true
            };
            option[selectizeInstance.settings.valueField] = input;
            option[selectizeInstance.settings.labelField] = input;

            callback(option);
          } :
          false :
        false;


    const allowEmptyOption = typeof(allBindings.get('allowEmptyOption')) === 'boolean' ?
      allBindings.get('allowEmptyOption') :
      true;

    const valueField = typeof (allBindings.get('valueField')) === 'string' ?
      allBindings.get('valueField') :
      'value';

    const labelField = typeof(allBindings.get('labelField')) === 'string' ?
      allBindings.get('labelField') :
      'text';

    const disabledField = typeof(allBindings.get('disabledField')) === 'string' ?
      allBindings.get('disabledField') :
      'disabled';

    const sortField = typeof (allBindings.get('sortField')) === 'string' || Array.isArray(allBindings.get('labelField')) ?
      allBindings.get('sortField') :
      '$order';

    const searchField = Array.isArray(allBindings.get('searchField')) ?
      allBindings.get('searchField') :
      ['text'];

    const render = typeof(allBindings.get('render')) === 'object' ?
      allBindings.get('render') :
      null;

    const selectedItemChangedCallback = key => {
      const selectedOption = selectizeInstance.options[key];
      value(selectedOption);
    };

    const selectizeConfig = {
      options: options,
      placeholder: placeholder,
      allowEmptyOption: allowEmptyOption,
      create: create,
      valueField: valueField,
      labelField: labelField,
      disabledField: disabledField,
      sortField: sortField,
      searchField: searchField,
      onChange: selectedItemChangedCallback,
      inputClass: 'form-control selectize-input',
      dropdownParent: 'body'
    };

    if (render) {
      selectizeConfig.render = render;
    }

    if (getOptionsCallback) {
      selectizeConfig.load = (query, callback) => {
        getOptionsCallback(query, callback);
      };
    }

    $(element).selectize(selectizeConfig);
    const selectizeInstance = $(element)[0].selectize;

    if (selectedItem) {
      const selectedItemValue = selectedItem[selectizeInstance.settings.valueField];
      selectizeInstance.setValue(selectedItemValue);
    }

    if (isDisabled) {
      selectizeInstance.disable();
    }
  }
};
