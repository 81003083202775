import ko from 'knockout';
import template from './myAccount.html';

export function MyAccountViewModel(routeParams) {

  const self = this;

  self.selectedPageComponentName = ko.observable('user-details');

  self.validationRules = getValidationRulesForComponent();

  self.selectedPageComponentName.subscribe(function () {
    self.validationRules = getValidationRulesForComponent();
  });

  function getValidationRulesForComponent() {
    const componentName = self.selectedPageComponentName();
    return routeParams[componentName];
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: MyAccountViewModel, template: template };

