import ko from 'knockout';

// Base components
import BaseSortDropdown from './BaseComponents/baseSortDropdown/baseSortDropdown';

// Standard components
import AddressLookup from './Components/addressLookup/addressLookup';
import AssociatedFileList from './Components/associatedFileList/associatedFileList';
import FileUploadSelector from './Components/fileUploadSelector/fileUploadSelector';
import FileUploadsListItem from './Components/fileUploadsListItem/fileUploadsListItem';
import FilterItems from './Components/filterItems/filterItems';
import CctvFolderUploadsListItem from './Components/cctvFolderUploadsListItem/cctvFolderUploadsListItem';
import GroupFilterArea from './Components/groupFilterArea/groupFilterArea';
import LeftMenu from './Components/leftMenu/leftMenu';
import TabMenu from './Components/tabMenu/tabMenu';
import MyAccountMenu from './Components/myAccountMenu/myAccountMenu';
import Pagination from './Components/pagination/pagination';
import ProgressBar from './Components/progressBar/progressBar';
import RequestsSortDropdown from './Components/requestsSortDropdown/requestsSortDropdown';
import SelectOrTextInput from './Components/selectOrTextInput/selectOrTextInput';
import UploadsList from './Components/uploadsList/uploadsList';
import UserFilterArea from './Components/userFilterArea/userFilterArea';
import DynamicRequestDetails from './Components/dynamicRequestDetails/dynamicRequestDetails';
import RequestDetails from './Components/requestDetails/requestDetails';
import RequestHeader from './Components/requestHeader/requestHeader';
import RequestList from './Components/requestList/requestList';
import BusinessUserDetails from './Components/businessUserDetails/businessUserDetails';
import DualListBox from './Components/dualListBox/dualListBox';
import CollapsedList from './Components/collapsedList/collapsedList';
import FilterPanel from './Components/filterPanel/filterPanel';
import CheckboxFilter from './Components/filterPanel/checkBoxFilter/checkboxFilter';
import TextFilter from './Components/filterPanel/textFilter/textFilter';
import CamerasSortDropdown from './Components/camerasSortDropdown/camerasSortDropdown';
import CreateReportModal from './Components/createReportModal/createReportModal';
import CctvUploadConfirmationModal from './Components/cctvUploadConfirmationModal/cctvUploadConfirmationModal';

import DiscussionMessage from './Features/DiscussionMessaging/Components/discussionMessage';
import DiscussionMessaging from './Features/DiscussionMessaging/Components/discussionMessaging';
import NewDiscussionMessage from './Features/DiscussionMessaging/Components/newDiscussionMessage';

// Debug components
import ContextDataInfo from './DebugComponents/contextDataInfo/contextDataInfo';
import InactivityInfo from './DebugComponents/inactivityInfo/inactivityInfo';
import RouteInfo from './DebugComponents/routeInfo/routeInfo';
import ViewModelInfo from './DebugComponents/viewModelInfo/viewModelInfo';

// Page components
import CameraDetails from './PageComponents/Portal/cameraDetails/cameraDetails';
import Cameras from './PageComponents/Portal/cameras/cameras';
import Home from './PageComponents/Portal/home/home';
import LoadingData from './PageComponents/Portal/loadingData/loadingData';
import Misc from './PageComponents/Portal/misc/misc';

import MyAccount from './PageComponents/Portal/myAccount/myAccount';
import BusinessDetails from './PageComponents/Portal/myAccount/businessDetails/businessDetails';
import ChangePassword from './PageComponents/Portal/myAccount/changePassword/changePassword';
import UserDetails from './PageComponents/Portal/myAccount/userDetails/userDetails';

import PageNotAvailable from './PageComponents/Portal/pageNotAvailable/pageNotAvailable';
import RequestFormSwitcher from './PageComponents/Portal/requestFormSwitcher/requestFormSwitcher';
import Requests from './PageComponents/Portal/requests/requests';
import Users from './PageComponents/Portal/users/users';
import GroupDetails from './PageComponents/Portal/groupDetails/groupDetails';
import GroupUsersTab from './PageComponents/Portal/groupDetails/usersTab/usersTab';
import GroupLocationTab from './PageComponents/Portal/groupDetails/locationTab/locationTab';
import Groups from './PageComponents/Portal/groups/groups';

import AuthorisationError from './PageComponents/PortalError/authorisationError';
import BusinessDisabled from './PageComponents/PortalError/businessDisabled';
import UnknownErrorGettingUserData from './PageComponents/PortalError/unknownErrorGettingUserData';
import UnknownUser from './PageComponents/PortalError/unknownUser';

import Registration from './PageComponents/Registration/registration/registration';
import ResetPasswordNotAvailable from './PageComponents/ResetPassword/notAvailable/notAvailable';

// Site components
import HelpNavItem from './SiteComponents/helpNavItem/helpNavItem';
import LanguageNavItem from './SiteComponents/languageNavItem/languageNavItem';
import LanguageNavItemPortal from './SiteComponents/languageNavItemPortal/languageNavItemPortal';
import TopNav from './SiteComponents/topNav/topNav';
import UploadingNavItem from './SiteComponents/uploadingNavItem/uploadingNavItem';
import UserNavItem from './SiteComponents/userNavItem/userNavItem';
import SessionExpiredOverlay from './SiteComponents/sessionExpiredOverlay/sessionExpiredOverlay';

export default class ComponentRegistration {

  static registerAllComponents() {
    ComponentRegistration.registerBaseComponents();
    ComponentRegistration.registerStandardComponents();
    ComponentRegistration.registerDebugComponents();
    ComponentRegistration.registerPageComponents();
    ComponentRegistration.registerSiteComponents();
  }

  static registerBaseComponents() {
    ko.components.register('base-sort-dropdown', BaseSortDropdown);
  }

  static registerStandardComponents() {
    ko.components.register('address-lookup', AddressLookup);
    ko.components.register('associated-file-list', AssociatedFileList);
    ko.components.register('file-upload-selector', FileUploadSelector);
    ko.components.register('file-uploads-list-item', FileUploadsListItem);
    ko.components.register('filter-items', FilterItems);
    ko.components.register('cctv-folder-uploads-list-item', CctvFolderUploadsListItem);
    ko.components.register('group-filter-area', GroupFilterArea);
    ko.components.register('left-menu', LeftMenu);
    ko.components.register('group-users-tab', GroupUsersTab);
    ko.components.register('group-location-tab', GroupLocationTab);
    ko.components.register('tab-menu', TabMenu);
    ko.components.register('my-account-menu', MyAccountMenu);
    ko.components.register('pagination', Pagination);
    ko.components.register('progress-bar', ProgressBar);
    ko.components.register('requests-sort-dropdown', RequestsSortDropdown);
    ko.components.register('select-or-text-input', SelectOrTextInput);
    ko.components.register('uploads-list', UploadsList);
    ko.components.register('user-filter-area', UserFilterArea);
    ko.components.register('dynamic-request-details', DynamicRequestDetails);
    ko.components.register('request-details', RequestDetails);
    ko.components.register('request-header', RequestHeader);
    ko.components.register('request-list', RequestList);
    ko.components.register('business-user-details', BusinessUserDetails);
    ko.components.register('dual-list-box', DualListBox);
    ko.components.register('collapsed-list', CollapsedList);
    ko.components.register('filter-panel', FilterPanel);
    ko.components.register('checkbox-filter', CheckboxFilter);
    ko.components.register('text-filter', TextFilter);
    ko.components.register('cameras-sort-dropdown', CamerasSortDropdown);

    ko.components.register('discussion-message', DiscussionMessage);
    ko.components.register('discussion-messaging', DiscussionMessaging);
    ko.components.register('new-discussion-message', NewDiscussionMessage);

    ko.components.register('create-report-modal', CreateReportModal);
    ko.components.register('cctv-upload-confirmation-modal', CctvUploadConfirmationModal);
  }

  static registerDebugComponents() {
    ko.components.register('context-data-info', ContextDataInfo);
    ko.components.register('inactivity-info', InactivityInfo);
    ko.components.register('route-info', RouteInfo);
    ko.components.register('view-model-info', ViewModelInfo);
  }

  static registerPageComponents() {
    ko.components.register('camera-details', CameraDetails);
    ko.components.register('cameras', Cameras);
    ko.components.register('users', Users);
    ko.components.register('group-details', GroupDetails);
    ko.components.register('groups', Groups);
    ko.components.register('home', Home);
    ko.components.register('loading-data', LoadingData);
    ko.components.register('misc', Misc);

    ko.components.register('my-account', MyAccount);
    ko.components.register('business-details', BusinessDetails);
    ko.components.register('change-password', ChangePassword);
    ko.components.register('user-details', UserDetails);

    // These components are displayed based on the "PortalStartupErrorType" enum value
    ko.components.register('authorisation-error', AuthorisationError);
    ko.components.register('business-disabled', BusinessDisabled);
    ko.components.register('unknown-error-getting-user-data', UnknownErrorGettingUserData);
    ko.components.register('unknown-user', UnknownUser);

    ko.components.register('page-not-available', PageNotAvailable);

    ko.components.register('request-form-switcher', RequestFormSwitcher);
    ko.components.register('requests', Requests);
    ko.components.register('registration', Registration);
    ko.components.register('reset-password-not-available', ResetPasswordNotAvailable);
  }

  static registerSiteComponents() {
    ko.components.register('help-nav-item', HelpNavItem);
    ko.components.register('language-nav-item', LanguageNavItem);
    ko.components.register('language-nav-item-portal', LanguageNavItemPortal);
    ko.components.register('top-nav', TopNav);
    ko.components.register('uploading-nav-item', UploadingNavItem);
    ko.components.register('user-nav-item', UserNavItem);
    ko.components.register('session-expired-overlay', SessionExpiredOverlay);

  }
}
