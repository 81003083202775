import ko from 'knockout';
import mapStylingHelper from '../helpers/mapStylingHelper';
import logger from '../../Utils/logger';

/**
 * Binding to show markers on the map. If this binding is placed within the map popup binding then
 * when a marker is clicked, the map popup binding will be used to display the tooltip / popup.
 */
export const mapMarkers = {
  init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {

    if (!element.id) {
      logger.error('UnhandledError', 'The map markers binding must be used on an element with an Id specified.');
    }

    if (typeof bindingContext.map === 'undefined') {
      logger.error('UnhandledError', 'The map markers binding must only be used inside the scope of a map binding.');
    }
  },
  update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {

    import(/* webpackChunkName: "open-layers" */ './openLayersDynamicModule') // Dynamically import large OpenLayers dependencies
        .then(olModule => {

          const Feature = olModule.Feature;
          const Point = olModule.Point;
          const VectorLayer = olModule.VectorLayer;
          const VectorSource = olModule.VectorSource;
          const fromLonLat = olModule.fromLonLat;

          const items = ko.unwrap(valueAccessor());

          // If there are no items then exit as the array has not yet been populated
          if (items.length === 0) {
            return;
          }

          const bindingsObject = getBindingsObject(allBindings);
          const map = bindingContext.map;
          const mapPopup = bindingContext.mapPopup;
          const layer = createNewLayer(map, element.id);
          let markerFeature;

          map.addLayer(layer);

          for (let i = 0; i < items.length; i++) {
            markerFeature = createMarkerFeature(items[i], i, bindingsObject);
            layer.getSource().addFeature(markerFeature);
          }

          if (bindingsObject.zoomToMarkers) {
            const extent = layer.getSource().getExtent();
            map.getView().fit(extent, map.getSize(), { maxZoom: 19 }); // Set max zoom to ensure we don't zoom too far in
          }

          // If the map popup parent binding exists then show popup when marker is clicked
          if (mapPopup) {

            const singleClickEventKey = map.on('click', function (e) {

              let feature;
              let item;

              map.forEachFeatureAtPixel(e.pixel, function (clickedFeature, clickedLayer) {
                if (clickedLayer === layer) {
                  feature = clickedFeature;
                }
              });

              if (feature) {
                e.stopPropagation(); // Prevent the click event from being handled anywhere else

                item = items[feature.get('index')];
                mapPopup.showPopup(feature, item, bindingsObject.dataType);
              }
            });

            ko.utils.domNodeDisposal.addDisposeCallback(element, function () {

              // Remove map click event listener
              map.on('click', singleClickEventKey);
            });
          }


          function createMarkerFeature(item, index, bindingsObject) {

            const coordinate = item[bindingsObject.coordinateProperty];
            const markerLonLat = [coordinate.longitude, coordinate.latitude];
            const markerPoint = new Point(fromLonLat(markerLonLat));

            const markerFeature = new Feature(markerPoint);
            const markerStyle = mapStylingHelper.createPointMarkerStyle(
                item[bindingsObject.textProperty],
                bindingsObject.dataType);
            markerFeature.setStyle(markerStyle);

            markerFeature.set('index', index);

            return markerFeature;
          }

          function createNewLayer(map, elementId) {

            const layers = map.getLayers();

            // If the layer already exists then remove it
            for (let i = 0; i < layers.getLength(); i++) {
              if (layers.item(i).get('name') === elementId) {
                map.removeLayer(layers.item(i));
              }
            }

            return new VectorLayer({
              source: new VectorSource(),
              name: elementId
            });
          }

          /**
         * Create an object for the binding properties in use
         * @param {*} allBindings
         * @return {{textProperty, coordinateProperty: string, dataType}}
         */
          function getBindingsObject(allBindings) {

            return {
              textProperty: allBindings.get('textProperty'),
              coordinateProperty: allBindings.get('coordinateProperty') || 'coordinate',
              dataType: allBindings.get('dataType'),
              zoomToMarkers: allBindings.get('zoomToMarkers') || false
            };
          }

        });
  }
};
