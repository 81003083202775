import ko from 'knockout';
import mapObservableHelper from '../helpers/mapObservableHelper';
import mapStylingHelper from '../helpers/mapStylingHelper';
import logger from '../../Utils/logger';

export const mapDraggableMarker = {
  init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    // Ignore that element, allBindings and viewModel arguments are not in use

    if (typeof bindingContext.map === 'undefined') {
      logger.error(
          'UnhandledError',
          'The draggable marker binding must only be used inside the scope of a map binding.');
    }

    const map = bindingContext.map;

    const coordinateObservable = mapObservableHelper.getAccessorObservable(valueAccessor);
    const markerLonLat = [coordinateObservable().longitude, coordinateObservable().latitude];

    import(/* webpackChunkName: "open-layers" */ './openLayersDynamicModule') // Dynamically import large OpenLayers dependencies
        .then(olModule => {

          const Collection = olModule.Collection;
          const Feature = olModule.Feature;
          const Point = olModule.Point;
          const VectorLayer = olModule.VectorLayer;
          const VectorSource = olModule.VectorSource;
          const toLonLat = olModule.toLonLat;
          const fromLonLat = olModule.fromLonLat;
          const Translate = olModule.Translate;

          const vectorLayer = new VectorLayer({
            source: new VectorSource()
          });

          let lastMapLngLat = [0, 0];

          map.addLayer(vectorLayer);

          // TODO: Check the dataType parameter exists before attempting to use
          const style = mapStylingHelper.createDraggableMarkerStyle(allBindings().dataType);

          const markerPoint = new Point(fromLonLat(markerLonLat));
          const markerFeature = new Feature(markerPoint);

          markerFeature.setStyle(style);

          vectorLayer.getSource().addFeature(markerFeature);

          const collection = new Collection([markerFeature]);
          const drag = new Translate({
            features: collection
          });
          map.addInteraction(drag);

          const coordinateSubscription = coordinateObservable.subscribe(function (newValue) {

            const observableLonLat = fromLonLat([newValue.longitude, newValue.latitude]);

            const mapLonLat = markerFeature.getGeometry().getCoordinates();

            const deltaX = observableLonLat[0] - mapLonLat[0];
            const deltaY = observableLonLat[1] - mapLonLat[1];

            markerFeature.getGeometry().translate(deltaX, deltaY);
          });

          markerFeature.on('change', onMarkerChange, markerFeature);

          ko.utils.domNodeDisposal.addDisposeCallback(element, function () {

            coordinateSubscription.dispose();

            // Remove map feature change event listener
            markerFeature.un('change', onMarkerChange);
          });

          function onMarkerChange(event) {

            const currentLonLat = toLonLat(event.target.getGeometry().getCoordinates());

            // If the marker hasn't moved then don't set the observable otherwise the observable subscription will
            // execute which will update the map and then fire this event again causing an infinite loop.
            if (currentLonLat[0] === lastMapLngLat[0] && currentLonLat[1] === lastMapLngLat[1]) {
              return;
            }

            lastMapLngLat = currentLonLat;

            coordinateObservable({ latitude: currentLonLat[1], longitude: currentLonLat[0] });
          }

        });
  }
};

