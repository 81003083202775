import * as ko from 'knockout';
import { Observable, ObservableArray } from 'knockout';
import GroupsRepository from '@/Repositories/groupsRepository';
import menuDataRepository from '@/Repositories/menuDataRepository';
import logger from '@/Utils/logger';
import redirectHelper from '@/Utils/redirectHelper';
import ValidationRulesService from '@/Validation/validationRulesService';
import contextData from '@/contextData';
import constants from '@/constants';
import DualListBoxItem from '@/Components/dualListBox/types/DualListBoxItem';
import DualListBoxIconDetails from '@/Components/dualListBox/types/DualListBoxIconDetails';
import GroupDetailsValidationModel from '@/Models/groupManagement/groupDetailsValidationModel';
import { GroupDto } from '@/Models/groupManagement/groupDto';
import { PersonaDisplayNameSummaryDto } from '@/Models/groupManagement/personaDisplayNameSummaryDto';
import { GroupMemberDto } from '@/Models/groupManagement/groupMemberDto';
import template from '@/PageComponents/Portal/groupDetails/groupDetails.html';

class GroupsDetailsViewModel {
  public titleResourceKey: Observable<string>;
  public formSubmitted: Observable<boolean>;
  public serverErrors: ObservableArray;
  public clientError: Observable;
  // Page mode related values and observables
  public pageMode: string;
  public groupDetails: any;
  public inAddMode: Observable<boolean>;
  public inEditMode: Observable<boolean>;
  public showDeletePrompt: Observable<boolean>;
  public selectedItemIdObservable: Observable<string>;
  public updateGroupFunc: (groupDto: any) => any;
  public deleteGroupFunc: (id: string) => any;
  public isDataAvailable: Observable<boolean>;
  private businessId: string = contextData.userData.business.businessId;
  private validationRulesService: any = null;

  private readonly availableUsers: ObservableArray<DualListBoxItem>;
  private readonly assignedUsers: ObservableArray<DualListBoxItem>;
  private readonly validationRules: any = null;
  private readonly tabItems: Array<any>;
  private readonly selectedTabItem: Observable;
  private readonly selectedTabComponentName: Observable<string>;
  private readonly initialCoordinate: Observable<Array<number>>;
  private readonly defaultCoordinate: Array<number>;

  // params passed to tab components
  private tabParams: any;

  constructor(params: any) {

    this.defaultCoordinate = contextData.userData.business.coordinate ?
      contextData.userData.business.coordinate :
      contextData.portalSettings.defaultMapView.coordinate;

    this.initialCoordinate = ko.observable(this.defaultCoordinate);
    this.formSubmitted = ko.observable(false);
    this.serverErrors = ko.observableArray();
    this.clientError = ko.observable(null);
    this.pageMode = params.pageMode;
    this.titleResourceKey = ko.observable('');
    this.inAddMode = ko.observable(false);
    this.inEditMode = ko.observable(false);
    this.showDeletePrompt = ko.observable(false);
    this.groupDetails = new GroupDetailsValidationModel();
    this.isDataAvailable = ko.observable(false);
    this.availableUsers = ko.observableArray();
    this.assignedUsers = ko.observableArray();
    this.selectedTabComponentName = ko.observable('group-users-tab');
    this.selectedItemIdObservable = params.selectedItemId;
    this.updateGroupFunc = params.updateGroupFunc;
    this.deleteGroupFunc = params.deleteGroupFunc;
    this.groupDetails.coordinate(this.defaultCoordinate);
    this.validationRulesService = new ValidationRulesService(this.groupDetails);
    this.validationRules = params.validationRules;
    this.validationRulesService.applyValidation(this.validationRules);
    this.selectedTabItem = ko.observable();
    this.tabItems = menuDataRepository.getGroupDetailsTabs();

    this.selectedTabItem.subscribe(newItem => {
      this.selectedTabComponentName(newItem.data.pageComponentName);
    });

    this.setActiveTabItem();

    if (this.pageMode === constants.pageMode.edit) {
      this.setEditPageModeObservables();
      this.selectItemIdChangeHandler(this.selectedItemIdObservable());

    } else if (this.pageMode === constants.pageMode.add) {
      this.setAddPageModeObservables();
      this.setAvailableUsers(params.allPersonas);
      this.isDataAvailable(true);

    } else {
      logger.error('UnhandledError', `The page mode ${this.pageMode} is not valid.`);
    }

    this.tabParams = {
      pageMode: this.pageMode,
      isDataAvailable: this.isDataAvailable,
      availableUsers: this.availableUsers,
      assignedUsers: this.assignedUsers,
      initialCoordinate: this.initialCoordinate,
      groupDetails: this.groupDetails,
      selectedItemIdObservable: this.selectedItemIdObservable,
      selectedTabItemObservable: this.selectedTabItem
    };
  }

  public setActiveTabItem = (): void => {

    let currentItem;
    let i;
    let activeItem;
    for (i = 0; i < this.tabItems.length; i++) {
      currentItem = this.tabItems[i];
      currentItem.isActive(false);
      if (currentItem.data.pageComponentName === this.selectedTabComponentName()) {
        activeItem = currentItem;
      }
    }
    activeItem.isActive(true);
    this.selectedTabItem(activeItem);
  };

  public addGroup = (): any => {
    this.clientError(null);
    this.serverErrors([]);
    if (!this.isSavingAllowed()) {
      return;
    }
    if (!this.groupDetails.isLocationAssigned()) {
      this.resetLocation();
    }
    const debugErrorMessage = 'An unexpected error occurred while attempting to add an new group.';
    GroupsRepository.addNewGroup(this.businessId, this.groupDetails, this.assignedUsers())
      .then(() => {
        logger.success('GroupAddedSummary');
        redirectHelper.redirectToHash('#groups');
      })
      .catch((jqXhr: any) => {
        this.formSubmitted(false);

        if (jqXhr.serverErrorMessages) {
          this.serverErrors(jqXhr.serverErrorMessages);
          return;
        }

        if (!jqXhr.errorHasBeenLogged) {
          logger.error('UnhandledError', debugErrorMessage, jqXhr);
        }
      });
  };

  public updateGroup = (): any => {
    this.clientError(null);
    this.serverErrors([]);
    if (this.selectedItemIdObservable() !==null && !this.isSavingAllowed()) {
      return;
    }
    const debugErrorMessage = 'An unexpected error occurred while attempting to update the group.';

    if (!this.groupDetails.isLocationAssigned()) {
      this.resetLocation();
    }

    GroupsRepository.updateGroup(this.selectedItemIdObservable(), this.businessId, this.groupDetails, this.assignedUsers())
      .then((groupDto: GroupDto) => {
        logger.success('GroupUpdatedSummary');
        this.formSubmitted(false);
        this.updateGroupFunc(groupDto);
      })
      .catch((jqXhr: any) => {
        this.formSubmitted(false);

        if (jqXhr.serverErrorMessages) {
          this.serverErrors(jqXhr.serverErrorMessages);
          return;
        }

        if (!jqXhr.errorHasBeenLogged) {
          logger.error('UnhandledError', debugErrorMessage, jqXhr);
        }
      });
  };

  public deleteGroup = (): void => {
    this.showDeletePrompt(true);
  };

  deleteGroupConfirmed = (): void => {
    this.showDeletePrompt(false);
    this.formSubmitted(true);
    this.serverErrors([]);

    const itemIdToDelete = this.selectedItemIdObservable();
    GroupsRepository.deleteGroup(this.businessId, itemIdToDelete)
      .then(() => {
        this.deleteGroupFunc(itemIdToDelete);
        logger.success('GroupDeletedSummary');
      })
      .catch((jqXhr: any) =>{
        this.formSubmitted(false);

        if (jqXhr.serverErrorMessages) {
          this.serverErrors(jqXhr.serverErrorMessages);
          return;
        }

        if (!jqXhr.errorHasBeenLogged) {
          logger.error('UnhandledError', 'An unexpected error occurred while attempting to delete the group.', jqXhr);
        }
      });
  };

  private isSavingAllowed = (): boolean => {
    if (this.groupDetails.clientErrors().length > 0) {
      this.groupDetails.clientErrors.showAllMessages(true);
      return false;
    }
    const validationFailureInfo = this.getStatusValidationInfo();
    if (validationFailureInfo) {
      this.clientError(validationFailureInfo.resourceKey);
      this.selectedTabComponentName(validationFailureInfo.tabName);
      this.setActiveTabItem();
      return false;
    }
    this.formSubmitted(true);
    return true;
  };

  private selectItemIdChangeHandler = (newValue: string): void => {
    if (this.pageMode !== constants.pageMode.edit) {
      return;
    }
    this.isDataAvailable(false);
    if (newValue !== null) {
      this.clientError(null);
      this.serverErrors([]);
      GroupsRepository.getGroupDetails(this.businessId, newValue)
        .then((groupDto: GroupDto) => {
          if (this.selectedItemIdObservable() !== groupDto.groupId) {
            return;
          }
          this.groupDetails.groupName(groupDto.groupDetails.groupName);
          if (groupDto.groupDetails.address) {
            this.groupDetails.address(groupDto.groupDetails.address);
            this.groupDetails.isLocationAssigned(true);
          } else {
            this.groupDetails.address(null);
            this.groupDetails.isLocationAssigned(false);
          }
          const initialCoord = groupDto.groupDetails.coordinate ?
            groupDto.groupDetails.coordinate : this.defaultCoordinate;
          this.groupDetails.coordinate(initialCoord);
          this.initialCoordinate(initialCoord);
          this.setAvailableUsers(groupDto.availablePersonas);
          this.setAssignedUsers(groupDto.assignedGroupMembers);
          this.isDataAvailable(true);
        })
        .catch((jqXhr: any) => {
          this.formSubmitted(false);

          if (jqXhr.serverErrorMessages) {
            this.serverErrors(jqXhr.serverErrorMessages);
            return;
          }

          if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnhandledError', 'An unexpected error occurred while attempting to to get group details.', jqXhr);
          }
        });
    }
  };

  private setAddPageModeObservables = (): void => {
    this.titleResourceKey('AddGroupTitle');
    this.inAddMode(true);
  };

  private setAvailableUsers = (availablePersonas: PersonaDisplayNameSummaryDto[]): void => {
    const availableUserArray: DualListBoxItem[] = availablePersonas
      .map((item: any) => new DualListBoxItem(item.personaId, item.personaDisplayName, false, this.getPersonaIconDetails(item)));
    this.availableUsers(availableUserArray);
  };

  private setAssignedUsers = (assignedGroupMembers: GroupMemberDto[]): void => {
    const assignedGroupMembersArray: DualListBoxItem[] = assignedGroupMembers
      .map((item: any) => new DualListBoxItem(
        item.memberPersona.personaId,
        item.memberPersona.personaDisplayName,
        item.isGroupPrimaryContact,
        this.getPersonaIconDetails(item.memberPersona)));
    this.assignedUsers(assignedGroupMembersArray);
  };

  private getPersonaIconDetails(persona: any): DualListBoxIconDetails | null {
    if (persona.isPersonaPreregistered) {
      return new DualListBoxIconDetails(constants.preRegisteredUserIconClass, 'UnregisteredUserTitle');
    }
    return null;
  }

  private setEditPageModeObservables = (): void => {
    this.titleResourceKey('');
    this.inEditMode(true);
    this.selectedItemIdObservable.subscribe(this.selectItemIdChangeHandler);
  };

  private getStatusValidationInfo = (): any => {
    if (this.assignedUsers().length > 0) {
      const groupHasPrimaryContacts = this.assignedUsers().some(item => item.isChecked());
      if (!groupHasPrimaryContacts) {
        return {
          resourceKey: { key: 'GroupNoPrimaryContact' },
          tabName: 'group-users-tab'
        };
      }
    }
    if (this.groupDetails.isLocationAssigned() && !this.groupDetails.address()?.trim()) {
      return {
        resourceKey: { key: 'GroupNoAddress' },
        tabName: 'group-location-tab'
      };
    }
    return null;
  };

  private resetLocation(): void {
    this.groupDetails.address(null);
    this.groupDetails.coordinate(null);
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: GroupsDetailsViewModel, template: template };
