import ko from 'knockout';

/**
 * @param {string} businessId
 * @param {object} coordinate
 * @param {object} [serverModel]
 */
function CameraModel(businessId, coordinate, serverModel) {

  const self = this;

  // Observables
  self.businessId = ko.observable(businessId);
  self.coordinate = ko.observable(coordinate);

  self.cameraId = ko.observable();
  self.cameraName = ko.observable();
  self.internalName = ko.observable();
  self.cameraMake = ko.observable();
  self.cameraModel = ko.observable();
  self.description = ko.observable();
  self.rowversion = ko.observable();

  self.clientErrors = ko.validation.group(self, { deep: true });

  if (serverModel) {
    populateObservables(serverModel);
  }

  function populateObservables(serverModel) {

    self.cameraId(serverModel.cameraId);
    self.cameraName(serverModel.cameraName);
    self.internalName(serverModel.internalName);
    self.cameraMake(serverModel.cameraMake);
    self.cameraModel(serverModel.cameraModel);
    self.description(serverModel.description);
    self.rowversion(serverModel.rowversion);
  }
}

export default CameraModel;
