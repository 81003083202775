import repositoryHelper from './repositoryHelper';
import AssociatedFileModel from '../Models/associatedFileModel';

function AssociatedFileRepository() {

  const self = this;

  const baseResourceUrl = 'api/associatedFile/';

  self.getAssociatedFileByRequestId = function (requestId) {

    const resourceUrl = baseResourceUrl + 'request/' + requestId;

    return new Promise(function (resolve, reject) {

      repositoryHelper.ajaxGet(resourceUrl)
          .then(function (result) {
            const assocaitedFilesModels = createAssociatedFilesModels(result);
            resolve(assocaitedFilesModels);
          })
          .catch(function (jqXhr) {
            reject(jqXhr);
          });
    });
  };

  self.downloadAssociatedFileById = associatedFileId => {
    const url = `${baseResourceUrl}/download/${associatedFileId}`;
    repositoryHelper.downloadPostRequest(url, { associatedFileId: associatedFileId });
  };

  function createAssociatedFilesModels(serverAssociatedFilesModels) {

    let serverAssociatedFileModel;
    const associatedFilesModels = [];

    for (let i = 0; i < serverAssociatedFilesModels.length; i++) {
      serverAssociatedFileModel = serverAssociatedFilesModels[i];
      associatedFilesModels.push(new AssociatedFileModel(serverAssociatedFileModel));
    }

    return associatedFilesModels;
  }
}

export default new AssociatedFileRepository();
