import logger from '@/Utils/logger';
import redirectHelper from '@/Utils/redirectHelper';

/**
 * This module is used to inspect error responses from Ajax calls and handle known scenarios
 */
function ErrorResponseHandler() {

  const self = this;

  /**
   * Inspect the error response for an unauthorised response or error response with translated error
   * messages and handle as appropriately.
   * @param {*} jqXhr
   * @param {ErrorCode[]} [errorCodesToIgnore] Well-known error codes that if returned should not be logged
   */
  self.handleErrorResponse = function (jqXhr, errorCodesToIgnore) {
    handleUnauthorisedResponse(jqXhr);
    handleForbiddenResponse(jqXhr, errorCodesToIgnore);
    handleTimeoutError(jqXhr);
    parseAndAddErrorMessages(jqXhr);
  };

  /**
   * Redirect user to login page if 401 returned as the user's token has expired
   * @param {*} jqXhr
   */
  function handleUnauthorisedResponse(jqXhr) {

    if (jqXhr.status === 401) {
      redirectHelper.tokenLoginRedirect();
    }
  }

  /**
   * Log a warning if 403 returned as the user's accesses forbidden resource
   * @param {*} jqXhr
   * @param {ErrorCode[]} [errorCodesToIgnore] Well-known error codes that if returned should not be logged
   */
  function handleForbiddenResponse(jqXhr, errorCodesToIgnore) {

    if (jqXhr.status === 403) {
      let errorObject = jqXhr.responseJSON;

      if (errorObject && typeof errorObject === 'string') {
        errorObject = JSON.parse(jqXhr.responseJSON);
      }

      // If we have an error object with an error code, add the code directly to the jqXhr for easier access
      // in higher up code.
      if (errorObject && errorObject.errorCode) {
        jqXhr.errorCode = errorObject.errorCode;
      }

      // If the caller has not specified to ignore this error code then log
      if (errorCodesToIgnore.length === 0 || !errorCodesToIgnore.includes(jqXhr.errorCode)) {
        logger.logForbiddenAccess();
        jqXhr.errorHasBeenLogged = true;
      }
    }
  }

  /**
   * Log specific warning if an AJAX request timed out
   * @param {*} jqXhr
   */
  function handleTimeoutError(jqXhr) {

    if (jqXhr.statusText === 'timeout') {
      logger.logTimeout();
      jqXhr.errorHasBeenLogged = true;
    }
  }

  /**
   * If the error contains translated error messages and the error was due to a bad request that the user
   * can recover from then add the translated error messages directly to the jqXhr object for user by
   * consumers of the repositories.
   * @param {*} jqXhr
   */
  function parseAndAddErrorMessages(jqXhr) {

    let errorObject = jqXhr.responseJSON;

    if (!errorObject) {
      return;
    }

    if (typeof errorObject === 'string') {
      errorObject = JSON.parse(jqXhr.responseJSON);
    }

    if (errorObject.errorMessages && errorObject.isUserRecoverable) {
      jqXhr.serverErrorMessages = errorObject.errorMessages;
    }
  }
}

export default new ErrorResponseHandler();
