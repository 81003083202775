import ko from 'knockout';
import contextData from '@/contextData';
import fileSizeFormatter from '@/Utils/fileSizeFormatter';
import template from './fileUploadSelector.html';

export function FileUploadSelectorViewModel(params) {

  const self = this;
  const addFileUploadsCallback = params.addFileUploadsCallback;
  const validateFileUploadsCallback = params.validateFileUploadsCallback;
  const addUploadsToRepositoryCallback = params.addUploadsToRepositoryCallback;
  let cachedCctvFolderFileList = [];

  self.maximumUploadSize = contextData.portalSettings.maximumUploadSize;

  self.formattedMaximumUploadSize =
    fileSizeFormatter.getFormattedFileSize(contextData.portalSettings.maximumUploadSize);

  self.maximumCctvFolderUploadSize = contextData.portalSettings.maximumCctvFolderUploadSize;

  self.formattedMaximumCctvFolderUploadSize =
    fileSizeFormatter.getFormattedFileSize(contextData.portalSettings.maximumCctvFolderUploadSize);

  self.dynamicCctvFolderExportExplanatoryText = params.dynamicCctvFolderExportExplanatoryText;

  self.isDirectorySupported = isDirectorySupported();

  self.isCctvUploadModalVisible = ko.observable(false);

  self.addFileUploads = fileList => {
    addFileUploadsCallback(fileList);
  };

  self.confirmCctvFolderUploads = fileList => {
    const isCctvFolderUploadValid = validateFileUploadsCallback(fileList);
    if (!isCctvFolderUploadValid) {
      return;
    }
    cacheCctvFolderFiles(fileList);
    self.isCctvUploadModalVisible(true);
  };

  self.processCctvFolderUpload = () => {
    addUploadsToRepositoryCallback(cachedCctvFolderFileList);
    self.isCctvUploadModalVisible(false);
  };

  function cacheCctvFolderFiles(fileList) {
    cachedCctvFolderFileList = [];
    Array.from(fileList)
        .forEach(file => {
          cachedCctvFolderFileList.push(file);
        });
  }
  function isDirectorySupported() {
    const tmpInput = document.createElement('input');
    if ('webkitdirectory' in tmpInput ||
      'mozdirectory' in tmpInput ||
      'odirectory' in tmpInput ||
      'msdirectory' in tmpInput ||
      'directory' in tmpInput) {
      return true;
    }

    return false;
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: FileUploadSelectorViewModel, template: template };
