import RequestModel from '../Models/requestModel';
import repositoryHelper from './repositoryHelper';

function RequestRepository() {

  const self = this;
  const baseResourceUrl = 'api/request/';

  self.getRequest = function (requestId) {

    const resourceUrl = baseResourceUrl + requestId;

    return new Promise(function (resolve, reject) {

      repositoryHelper.ajaxGet(resourceUrl)
          .then(function (request) {
            const requestModel = new RequestModel(request);
            resolve(requestModel);
          })
          .catch(function (jqXhr) {
            reject(jqXhr);
          });
    });
  };

  /**
   * @param {object} requestDataModel
   * @return {Promise}
   */
  self.updateRequest = function (requestDataModel) {

    const resourceUrl = baseResourceUrl + requestDataModel.requestId + '?submitResponse=false';
    return repositoryHelper.ajaxPut(resourceUrl, requestDataModel);
  };

  /**
   * @param {object} requestDataModel
   * @return {Promise}
   */
  self.updateRequestAndSubmitResponse = function (requestDataModel) {
    const resourceUrl = baseResourceUrl + requestDataModel.requestId + '?submitResponse=true';
    return repositoryHelper.ajaxPut(resourceUrl, requestDataModel);
  };

}

export default new RequestRepository();
