import ko from 'knockout';
import logger from '../../Utils/logger';
import template from './selectOrTextInput.html';

export function SelectOrTextInputViewModel(params) {

  const self = this;
  let items = params.items;
  const selectedItemParam = params.selectedItem;
  const defaultSelectedItem = ko.observable({
    text: null,
    value: null
  });

  self.isReadOnly = false;
  if (params.isReadOnly) {
    self.isReadOnly = params.isReadOnly;
  }

  self.labelResourceKey = null;
  if (params.labelResourceKey) {
    self.labelResourceKey = params.labelResourceKey;
  }

  self.isLabelVisible = false;
  if (params.isLabelVisible) {
    self.isLabelVisible = params.isLabelVisible;
  }

  self.glyphIcon = null;
  if (params.glyphIcon) {
    self.glyphIcon = params.glyphIcon;
  }

  self.placeholderResourceKey = '';
  if (params.placeholderResourceKey) {
    self.placeholderResourceKey = params.placeholderResourceKey;
  }

  const itemsIsArray = items && Array.isArray(items);
  const itemsIsObservableArray = items && ko.isObservable(items) && 'push' in items;

  if (itemsIsArray) {
    items = ko.observableArray(items);
  } else if (!itemsIsObservableArray) {
    logger.error('UnhandledError', 'The \'items\' parameter must either be an array or an observableArray.');
  }

  if (!params.textProperty || !params.valueProperty) {
    logger.error(
        'UnhandledError',
        'The the required parameter \'textProperty\' and / or \'valueProperty\' are missing.');
  }

  // Take copy of items array so that if selected item is added then original is not affected.
  items = items.slice();

  self.selectedItem = selectedItemParam || defaultSelectedItem;

  if (self.isReadOnly) {
    setupReadOnlyTextbox(selectedItemParam, params.textProperty);
  } else if (items && items.length) {
    setupDropdownObservables(items, selectedItemParam, params.textProperty, params.valueProperty);
  } else {
    setupTextInputObservables(selectedItemParam, params.textProperty, params.valueProperty);
  }

  function setupDropdownObservables(items, selectedItemParam, textProperty, valueProperty) {

    self.items = items;
    self.textProperty = textProperty;
    self.valueProperty = valueProperty;

    self.renderDropdown = true;
    self.renderTextInput = false;
    self.renderTextbox = false;

    // If the selected item wasn't passed across as an observable or the selected item text doesn't exist
    // the don't bother to determine if the selected item is already in the list of items / do nothing.
    if (!ko.isObservable(selectedItemParam) || !selectedItemParam()[textProperty]) {
      return;
    }

    const selectedItemExists = itemsContainsSelectedItem(items, textProperty, valueProperty, selectedItemParam);

    // If the selected item doesn't exist then create it. If there isn't a value for the selected item then
    // use the text as the selected item value.
    if (!selectedItemExists) {
      if (!selectedItemParam()[valueProperty]) {
        const updatedSelectedItemParam = {};
        updatedSelectedItemParam[textProperty] = selectedItemParam()[textProperty];
        updatedSelectedItemParam[valueProperty] = selectedItemParam()[textProperty];

        selectedItemParam(updatedSelectedItemParam);
      }

      const newItem = {};
      newItem[textProperty] = selectedItemParam()[textProperty];
      newItem[valueProperty] = selectedItemParam()[valueProperty];

      items.push(newItem);
    }
  }

  function setupTextInputObservables(selectedItemParam, textProperty, valueProperty) {

    self.textInputValue = ko.observable(null);
    self.textInputValue.subscribe(function (newValue) {
      const newItem = {
        added: true
      };
      newItem[textProperty] = newValue;
      newItem[valueProperty] = newValue;

      self.selectedItem(newItem);
    });

    if (selectedItemParam) {
      self.textInputValue(selectedItemParam()[textProperty]);
    }

    self.renderDropdown = false;
    self.renderTextInput = true;
    self.renderTextbox = false;
  }

  function setupReadOnlyTextbox(selectedItemParam, textProperty) {

    self.textboxValue = '';
    if (selectedItemParam) {
      self.textboxValue = selectedItemParam()[textProperty];
    }

    self.renderDropdown = false;
    self.renderTextInput = false;
    self.renderTextbox = true;
  }

  /**
   * Determine if the items observableArray contains the selected item by checking for a match on the
   * value property or the text property if the selectedItemParam doesn't contain a value.
   * @param {*} items Knockout observableArray
   * @param {string} textProperty
   * @param {string} valueProperty
   * @param {*} selectedItemParam
   * @return {boolean}
   */
  function itemsContainsSelectedItem(items, textProperty, valueProperty, selectedItemParam) {

    let matchedItem;

    // If the selected item parameter value exists then attempt to match on the value property
    if (selectedItemParam()[valueProperty]) {
      matchedItem = getItemForMatchingPropertyValue(items, valueProperty, selectedItemParam()[valueProperty]);
      return (matchedItem !== null);
    }

    // If the selected item text exists then attempt to match on the text property. If a match is found
    // then update the selectedItemParam to contain the text and value property.
    if (selectedItemParam()[textProperty]) {

      matchedItem = getItemForMatchingPropertyValue(items, textProperty, selectedItemParam()[textProperty]);

      if (matchedItem) {

        selectedItemParam({
          textProperty: selectedItemParam()[textProperty],
          valueProperty: matchedItem[valueProperty]
        });

        return true;
      }
    }

    return false;
  }

  /**
   *
   * @param {*} items Knockout observableArray
   * @param {string} propertyName
   * @param {*} valueToMatch
   * @return {*}
   */
  function getItemForMatchingPropertyValue(items, propertyName, valueToMatch) {

    let item = null;

    ko.utils.arrayForEach(items, function (currentItem) {

      if (currentItem[propertyName] === valueToMatch) {
        item = currentItem;
      }
    });

    return item;
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: SelectOrTextInputViewModel, template: template };


