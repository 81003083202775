import resourceHelper from '../Utils/resourceHelper';
import { DataRequestField } from './dataRequestField';
import { RequestClaim } from './requestClaim';

function ModelsHelper() {

  const self = this;

  self.mapDataRequestFields = function createDataRequestFields(serverDataRequestFields) {

    const cameraNames = serverDataRequestFields.requestedCameraNames ||
      resourceHelper.getString('ReferToRequestDescription');

    return new DataRequestField(
        serverDataRequestFields.requestedStartTime,
        serverDataRequestFields.requestedEndTime,
        cameraNames
    );
  };

  self.mapRequestClaims = function createRequestClaims(requestClaims) {
    return requestClaims.map(x => new RequestClaim(x.key, x.displayName, x.value));
  };
}

export default new ModelsHelper();
