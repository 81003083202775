import ko from 'knockout';
import CameraModel from '../../../Models/cameraModel';
import cameraRepository from '../../../Repositories/cameraRepository';
import logger from '../../../Utils/logger';
import redirectHelper from '../../../Utils/redirectHelper';
import ValidationRulesService from '../../../Validation/validationRulesService';
import contextData from '../../../contextData';
import constants from '../../../constants';
import template from './cameraDetails.html';

export function CameraDetailsViewModel(routeParams) {

  const self = this;
  const business = contextData.userData.business;
  let validationRulesService = null;
  let validationRules = null;
  const defaultZoomLevel = 17;
  let initialCoordinate = contextData.portalSettings.defaultMapView.coordinate;

  if (business.coordinate) {
    initialCoordinate = business.coordinate;
  }

  self.formSubmitted = ko.observable(false);
  self.showCoordinateModal = ko.observable(false); // Flags if modal informing user that marker not moved is displayed
  self.serverErrors = ko.observableArray();

  // Page mode related values and observables
  self.pageMode = routeParams.pageMode;
  self.camera = null;
  self.titleResourceKey = ko.observable();
  self.inAddMode = ko.observable(false);
  self.inEditMode = ko.observable(false);
  self.mapCenterCoordinate = ko.observable(initialCoordinate);
  self.mapZoomLevel = ko.observable(defaultZoomLevel);

  self.addressLookupCoordinate = ko.observable();
  self.addressLookupCoordinate.subscribe(function (newCoordinate) {
    self.mapCenterCoordinate(newCoordinate);
    self.camera.coordinate(newCoordinate);
    self.mapZoomLevel(defaultZoomLevel);
  });

  self.originalCameraCoordinate = ko.observable();
  self.coordinateChangedCheckRequired = ko.observable(false); // Flags if marker not moved check required

  if (self.pageMode === constants.pageMode.edit) {

    const camera = routeParams.camera;
    validationRules = camera.validationRules;
    setEditPageModeObservables(business, camera);

  } else if (self.pageMode === constants.pageMode.add) {

    validationRules = routeParams.validationRules;
    setAddPageModeObservables(business, routeParams.lat, routeParams.long);
  } else {
    logger.error('UnhandledError', 'The page mode \'' + self.pageMode + '\' isn\'t valid.');
  }

  validationRulesService = new ValidationRulesService(self.camera);
  validationRulesService.applyValidation(validationRules);

  self.addCameraWithoutCoordinateCheck = function () {

    self.showCoordinateModal(false);
    self.coordinateChangedCheckRequired(false);
    self.addCamera();
  };

  self.addCamera = function () {
    const debugErrorMessage = 'An unexpected error occurred while attempting to add an new camera.';
    performAction('addNewCamera', 'CameraAddedSummary', debugErrorMessage);
  };

  self.updateCamera = function () {
    const debugErrorMessage = 'An unexpected error occurred while attempting to update the camera.';
    performAction('updateCamera', 'CameraUpdatedSummary', debugErrorMessage);
  };

  // TODO: Implement the delete camera functionality fully
  self.showDeletePrompt = ko.observable(false);

  self.deleteCamera = function () {
    self.showDeletePrompt(true);
  };

  self.deleteCameraConfirmed = function () {
    self.showDeletePrompt(false);

    self.formSubmitted(true);
    self.serverErrors([]);

    cameraRepository.deleteCamera(self.camera.cameraId())
        .then(function () {
          logger.success('CameraDeletedSummary');
          redirectHelper.redirectToHash('#cameras');
        })
        .catch(function (jqXhr) {
          self.formSubmitted(false);

          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
            return;
          }

          if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnhandledError', 'An unexpected error occurred while attempting to delete the camera.', jqXhr);
          }
        });
  };

  function performAction(cameraRepositoryFunctionName, successSummaryResourceKey, debugErrorMessage) {

    if (self.camera.clientErrors().length > 0) {
      self.camera.clientErrors.showAllMessages(true);
      return;
    }

    const showCoordinatePrompt = self.coordinateChangedCheckRequired() &&
      !cameraCoordinateHasChanged(self.originalCameraCoordinate, self.camera.coordinate);

    if (showCoordinatePrompt) {
      self.showCoordinateModal(true);
      return;
    }

    self.formSubmitted(true);
    self.serverErrors([]);

    const cameraData = ko.toJS(self.camera);

    cameraRepository[cameraRepositoryFunctionName](cameraData)
        .then(function () {

          logger.success(successSummaryResourceKey);
          redirectHelper.redirectToHash('#cameras');
        })
        .catch(function (jqXhr) {
          self.formSubmitted(false);

          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
            return;
          }

          if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnhandledError', debugErrorMessage, jqXhr);
          }
        });
  }

  function setAddPageModeObservables(business, routeParamsLat, routeParamsLong) {

    // When lat and long route parameters exist then these should take precedence
    let coordinate = createRouteParamCoordinate(routeParamsLat, routeParamsLong);

    if (!coordinate) {
      coordinate = self.mapCenterCoordinate();
      self.coordinateChangedCheckRequired(true);
    }

    self.titleResourceKey('AddCameraTitle');
    self.inAddMode(true);
    self.originalCameraCoordinate(coordinate);
    self.camera = new CameraModel(business.businessId, coordinate);
  }

  function setEditPageModeObservables(business, camera) {

    self.titleResourceKey('EditCameraTitle');
    self.inEditMode(true);
    self.mapCenterCoordinate(camera.coordinate);
    self.originalCameraCoordinate(camera.coordinate);
    self.camera = new CameraModel(business.businessId, camera.coordinate, camera);
  }

  function createRouteParamCoordinate(routeParamsLat, routeParamsLong) {

    if (routeParamsLat && routeParamsLong) {
      return {
        latitude: parseFloat(routeParamsLat),
        longitude: parseFloat(routeParamsLong)
      };
    }

    return null;
  }

  /**
   * @param {function} originalCameraCoordinate
   * @param {function} cameraCoordinate
   * @return {boolean}
   */
  function cameraCoordinateHasChanged(originalCameraCoordinate, cameraCoordinate) {

    if (originalCameraCoordinate().latitude !== cameraCoordinate().latitude) {
      return true;
    }

    if (originalCameraCoordinate().longitude !== cameraCoordinate().longitude) {
      return true;
    }

    return false;
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: CameraDetailsViewModel, template: template };

