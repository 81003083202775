import BusinessModel from '../Models/businessModel';
import CameraSummaryModel from '../Models/cameraSummaryModel';
import RequestAssigneeSummaryModel from '../Models/requestAssigneeSummaryModel';
import PageResultModel from '../Models/pageResultModel';
import repositoryHelper from './repositoryHelper';
import contextData from '../contextData';

function CameraSummaryRepository() {

  const self = this;
  const baseResourceUrl = 'api/business/';

  self.updateBusinessData = function (businessData) {

    const business = contextData.userData.business;
    const resourceUrl = baseResourceUrl + business.businessId;

    return new Promise(function (resolve, reject) {
      repositoryHelper.ajaxPut(resourceUrl, businessData)
          .then(function (serverBusinessData) {
            const businessData = new BusinessModel(serverBusinessData);
            resolve(businessData);
          })
          .catch(function (jqXhr) {
            reject(jqXhr);
          });
    });
  };

  /**
   * Retrieve the cameras for the business with the optional sorting and paging parameters.
   * @param {string} [sortField] String value of the CameraSortField enum
   * @param {string} [sortOrder] String value of the SortOrder enum
   * @param {number} [page] Page number to retrieve results for
   * @param {string} [previousCameraId] String value of previous camera Id Guid accessed
   * @return {PageResultModel[]} Page results object with results property
   */
  self.getCameras = function (sortField, sortOrder, page, previousCameraId) {

    sortField = sortField || null;
    sortOrder = sortOrder || null;
    page = page || null;

    const business = contextData.userData.business;
    let resourceUrl = baseResourceUrl + business.businessId + '/cameraSummary/';
    resourceUrl += '?sortField=' + sortField + '&sortOrder=' + sortOrder + '&page=' + page;

    return new Promise(function (resolve, reject) {

      repositoryHelper.ajaxGet(resourceUrl)
          .then(function (serverPageResult) {

            const cameraModels = serverPageResult.results.map(function (cameraSummary) {
              return new CameraSummaryModel(cameraSummary, previousCameraId);
            });

            const pageResult = new PageResultModel(serverPageResult, cameraModels);

            resolve(pageResult);
          })
          .catch(function (jqXhr) {
            reject(jqXhr);
          });
    });
  };

  /**
   * Retrieve all the camera names for the busines and wrap each camera name up in an object with the
   * property cameraName.
   * @return {Object[]} Array of objects with the property cameraName
   */
  self.getCameraNames = function () {

    const business = contextData.userData.business;
    const resourceUrl = baseResourceUrl + business.businessId + '/cameraName/';

    return new Promise(function (resolve, reject) {

      repositoryHelper.ajaxGet(resourceUrl)
          .then(function (serverNames) {

            const nameObjects = serverNames.map(function (serverName) {
              return {
                cameraName: serverName
              };
            });

            resolve(nameObjects);
          })
          .catch(function (jqXhr) {
            reject(jqXhr);
          });
    });
  };

  self.getRequestAssigneeSummaries = function () {
    const business = contextData.userData.business;
    const resourceUrl = baseResourceUrl + business.businessId + '/requestAssigneeSummary/';

    return new Promise(function (resolve, reject) {
      repositoryHelper.ajaxGet(resourceUrl)
          .then(function (serverResponse) {
            const requestAssigneeSummaryModels = serverResponse.results.map(serverModel =>
              new RequestAssigneeSummaryModel(serverModel)
            );

            resolve(requestAssigneeSummaryModels);
          })
          .catch(function (jqXhr) {
            reject(jqXhr);
          });
    });
  };
}

export default new CameraSummaryRepository();
