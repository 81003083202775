/**
 * @param {object} serverModel
 */
function RequestAssigneeSummaryModel(serverModel) {

  const self = this;

  self.assigneeType = serverModel.assigneeType;
  self.id = serverModel.id;
  self.name = serverModel.name;
  self.isDisabled = serverModel.isDisabled;
  self.isGroupEmpty = serverModel.isGroupEmpty;

  // Selectize.js returns only selected item id and name but assignee item is identified by id + type so we need a combined identifier.
  self.value = `${self.assigneeType}-${self.id}`;
}

export default RequestAssigneeSummaryModel;
