import template from './fileUploadsListItem.html';
import ko from 'knockout';

export function FileUploadListItemViewModel(params) {

  const self = this;

  self.fileUpload = params.fileUpload;
  self.isReadOnly = params.isReadOnly;
  self.removing = ko.observable(false);
  self.removeUpload = function () {
    self.removing(true);
    params.removeUploadCallback(self.fileUpload).catch(() => {
      self.removing(false);
    });
  };
}

// The default export returns the component details object to register with KO
export default { viewModel: FileUploadListItemViewModel, template: template };
