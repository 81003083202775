import ko from 'knockout';
import requestSummaryRepository from '../../../Repositories/requestSummaryRepository';
import localStorageHelper, { LocalStorageKeyType } from '../../../Utils/localStorageHelper';
import logger from '../../../Utils/logger';
import contextData from '../../../contextData';
import { FilterModel } from '../../../Components/filterPanel/Models/filterModel';

import template from './requests.html';

export function RequestsViewModel(routeParams) {

  const self = this;
  const usePersistedPage = routeParams.usePreviousViewSettings;
  const previousRequestId = routeParams.previousRoute.id;
  const initialFilter = routeParams.initialFilter;

  self.requests = ko.observableArray();

  self.loadingData = ko.observable(true);
  self.previousResultsExist = ko.observable(false);

  self.showLoading = ko.pureComputed(function () {
    return self.loadingData() && !self.previousResultsExist();
  }, self);

  self.disableResultsTable = ko.pureComputed(function () {
    return self.loadingData() && self.previousResultsExist();
  }, self);

  self.showNoResults = ko.pureComputed(function () {
    return !self.requests().length && !self.loadingData();
  }, self);

  self.showRequestList = ko.pureComputed(function () {
    return !!self.requests().length && (!self.loadingData() || self.previousResultsExist());
  }, self);

  // Pagination observables
  self.currentPage = ko.observable(1);
  self.pageSize = ko.observable();
  self.total = ko.observable();

  self.filterChangeHandler = function () {

    self.currentPage.disabled = true;
    self.currentPage(1);
    self.currentPage.disabled = false;

    updateResults();
  };

  self.filter = new FilterModel(self.filterChangeHandler, routeParams.page, initialFilter);

  self.resetFilterCriteriaToDefault = function () {
    this.filter.applyDefaultFilterCriteria();
    updateResults();
  };

  // Set the default select input value for sort field and sort order which is pipe delimited
  self.selectedSortValue = ko.observable(contextData.requestsSort.sortField + '|' + contextData.requestsSort.sortOrder);

  if (usePersistedPage) {
    setPageFromStorage();
  }

  self.selectedSortValue.subscribe(function (newValue) {

    const sortValues = newValue.split('|');
    contextData.requestsSort.sortField = sortValues[0];
    contextData.requestsSort.sortOrder = sortValues[1];

    self.currentPage.disabled = true;
    self.currentPage(1);
    self.currentPage.disabled = false;

    updateResults();
  });

  self.currentPage.subscribe(function () {

    if (self.currentPage.disabled !== true) {
      updateResults();
    }
  });

  self.click = function (request) {
    request.isRequestLastAccessed(true);
    return true; // Return true so browser follows the href link to request details screen
  };

  updateResults();

  function updateResults() {

    const filterCriteria = self.filter.getSelectedFilterCriteria();
    const sortValues = self.selectedSortValue().split('|');
    const sortField = sortValues[0];
    const sortOrder = sortValues[1];

    const page = self.currentPage();

    localStorageHelper.setSessionValue(LocalStorageKeyType.RequestsPage, page);

    self.loadingData(true);

    requestSummaryRepository.getRequests(filterCriteria, sortField, sortOrder, page, previousRequestId)
        .then(function (pageResult) {

          self.loadingData(false);
          self.requests(pageResult.results);

          self.pageSize(pageResult.pageSize);
          self.total(pageResult.total);

          self.filter.updateFilterCriteria(pageResult.filterCriteria);

          if (pageResult.total) {
            self.previousResultsExist(true);
          } else {
            self.previousResultsExist(false);
          }
        })
        .catch(function (jqXhr) {

          self.loadingData(false);

          if (!jqXhr.errorHasBeenLogged) {
            const debugMessage = 'An unexpected error occurred while attempting to retrieve the requests.';
            logger.error('UnhandledError', debugMessage, jqXhr);
          }
        });
  }

  function setPageFromStorage() {
    const persistedPage = localStorageHelper.getSessionValue(LocalStorageKeyType.RequestsPage);

    if (!persistedPage) {
      return;
    }
    self.currentPage(persistedPage);
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: RequestsViewModel, template: template };
