import ko from 'knockout';
import template from './filterItems.html';
import resourceHelper from '../../Utils/resourceHelper';

export function FilterItemsViewModel(params) {

  const self = this;
  self.id = params.id;
  self.filterDataModels = params.filterDataModels;
  self.filteredIds = params.filteredIds;
  self.labelText = resourceHelper.getString(params.labelTextKey);
  self.filterCriteriaValue = params.filterCriteriaValue ? params.filterCriteriaValue : ko.observable();
  self.isLabelHidden = params.isLabelHidden ? params.isLabelHidden : false;

  self.filterDataModels.subscribe(function (newValue) {
    self.filterCriteriaValue(null);
  });

  self.filterCriteriaValue.subscribe(function (newValue) {
    self.filteredIds.removeAll();
    if (newValue === undefined || newValue === null || newValue.length <= 0) {
      ko.utils.arrayForEach(self.filterDataModels(), o => self.filteredIds.push(o.id));
    } else {
      ko.utils.arrayForEach(self.filterDataModels(),
          model => {
            if (model.isMultipleFilteringMode) {
              if (isRelatedItem(newValue, model.primaryFilteredField) ||
              isRelatedItem(newValue, model.secondaryFilteredField)) {
                self.filteredIds.push(model.id);
              }
            } else {
              if (isRelatedItem(newValue, model.primaryFilteredField)) {
                self.filteredIds.push(model.id);
              }
            }
          });
    }
  });

  function isRelatedItem(criteria, filterObject) {
    const lowerCaseCriteria = criteria.toLowerCase();
    if (Array.isArray(filterObject)) {
      let relationCount = filterObject.filter(o => o.toLowerCase().startsWith(lowerCaseCriteria)).length;
      const lowerRowItems = filterObject.join(' ').toLowerCase();

      if (lowerRowItems.startsWith(lowerCaseCriteria)) {
        relationCount++;
      }

      return relationCount > 0;
    } else {
      return filterObject.toLowerCase().includes(lowerCaseCriteria);
    }
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: FilterItemsViewModel, template: template };
