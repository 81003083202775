import ko from 'knockout';
import constants from '../../constants';
import resourceHelper from '../../Utils/resourceHelper';
import CctvFolderUploadModel from '../../Models/cctvfolderUploadModel';
import template from './uploadsList.html';

export function UploadsListViewModel(params) {

  const self = this;

  self.maxUploadsForCameraNameAndCommentInputs = constants.maxUploadsForCameraNameAndCommentInputs;

  self.uploadTypes = constants.uploadTypes;

  self.isReadOnly = params.isReadOnly;
  self.includeCameraSelection = params.includeCameraSelection;

  self.uploads = params.uploads;
  self.fileUploads = ko.computed(function () {
    return ko.utils.arrayFilter(self.uploads(), function (upload) {
      return upload.isFile || !upload.rootFolderName;
    });
  }, this);
  self.cctvfolderUploads = ko.computed(function () {
    const uploads = ko.utils.arrayFilter(self.uploads(), function (upload) {
      return upload.isCctv || upload.rootFolderName;
    });

    const folderDictionary = groupBy(uploads, 'rootFolderName');

    const folders = [];
    for (const key in folderDictionary) {
      if (Object.prototype.hasOwnProperty.call(folderDictionary, key)) {
        folders.push(new CctvFolderUploadModel(folderDictionary[key]));
      }
    }

    return folders;
  });
  self.uploadListItems = ko.computed(function () {
    return self.cctvfolderUploads().concat(self.fileUploads()).sort(function (x, y) {
      return new Date(x.addedTimestamp) - new Date(y.addedTimestamp);
    });
  });

  self.cameraNameObjects = params.cameraNameObjects;

  self.removeUploadCallback = params.removeUploadCallback;

  // Property to hold the translated string to display if camera name is missing / null
  self.cameraNameMissing = resourceHelper.getString('CameraNameMissing');

  function groupBy(items, key) {
    return items.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }
}

// The default export returns the component details object to register with KO
export default { viewModel: UploadsListViewModel, template: template };


