import { Observable, ObservableArray } from 'knockout';
import DualListBoxItem from './DualListBoxItem';
import { DualListBoxHeaderSize } from './DualListBoxHeaderSize';

export class DualListBoxOptions {
  public localizedStringKeys: { [str: string]: string };
  constructor(
    public readonly headersSize: DualListBoxHeaderSize = DualListBoxHeaderSize.standard,
    public availableItems: ObservableArray<DualListBoxItem>,
    public assignedItems: ObservableArray<DualListBoxItem>,
    public isDataAvailable: Observable<boolean>,
    private overrideStringKeys: { [str: string]: string } = {}) {

    const defaultStringKeys = {
      'AreaTitleKey': 'DualListBoxAreaTitleKey',
      'AvailableItemsTitleKey': 'DualListBoxAvailableItemsTitleKey',
      'AssignedItemsTitleKey': 'DualListBoxAssignedItemsTitleKey',
      'CheckedItemTooltipKey': 'DualListBoxCheckedItemTooltipKey',
      'FilterAreaTitleKey': 'DualListBoxFilterAreaTitleKey',
      'ResAriaLabelCheckedItemKey': 'DualListBoxCheckedItemAriaLabelKey',
      'ResAriaLabelAddKey': 'DualListBoxResAriaLabelAddKey',
      'ResAriaLabelRemoveKey': 'DualListBoxResAriaLabelRemoveKey',
      'ResAriaLabelAddAllKey': 'DualListBoxResAriaLabelAddAllKey',
      'ResAriaLabelRemoveAllKey': 'DualListBoxResAriaLabelRemoveAllKey'
    };
    this.localizedStringKeys = Object.assign(defaultStringKeys, overrideStringKeys);
  }
}

