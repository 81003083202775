import ko from 'knockout';
import constants from '../constants';
import contextData from '../contextData';

function RequestUploadValidation() {

  const self = this;

  /**
   * @typedef {Object} resourceInfo
   * @property {string} key The key which is presented in the BusinessPortal.Resources project.
   * @property {Object} [params] A set of parameters used in resource string.
   */
  /**
   * Checks if the uploads for a request are in the correct state to allow the request to be submitted.
   * If the validation fails, a resourceInfo object for the failure message to display to the user is returned
   * else if everything is OK, null is returned.
   * @param {UploadModel[]} uploads Array of UploadModel for each file that already exists
   * @param {bool} areUploadsOptional Flag indicates whether at least one uploaded file is required
   * @return {resourceInfo|null}
   */
  self.getStatusValidationResourceInfo = function (uploads, areUploadsOptional = false) {

    if (uploads.length === 0) {
      if (!areUploadsOptional) {
        return { key: 'NoFilesUploaded' };
      }

      return null;
    }

    const statuses = uploads.map(function (upload) {
      return upload.statusName();
    });

    const containsAuthorising = statuses.indexOf(constants.uploadStatuses.authorising) > -1;
    const containsPending = statuses.indexOf(constants.uploadStatuses.pending) > -1;
    const containsUploading = statuses.indexOf(constants.uploadStatuses.uploading) > -1;
    const containsFailed = statuses.indexOf(constants.uploadStatuses.failed) > -1;

    if (containsAuthorising || containsPending || containsUploading) {
      return { key: 'UploadsStillInProgress' };
    }

    if (containsFailed) {
      return { key: 'FailedUploadsExist' };
    }

    return null;
  };

  /**
   * Perform validation to check if new uploads can be added. If the validation fails, a resourceInfo object for
   * the failure message to display to the user is returned else if everything is OK, null is returned.
   * @param {FileList} fileList Native browser FileList object for files to add
   * @param {UploadModel[]} uploads Array of UploadModel for each file that already exists
   * @return {resourceInfo|null}
   */
  self.getAddUploadsValidationResourceInfo = function (fileList, uploads) {

    if (fileList.length === 0) {
      return { key: 'UploadNoFilesFound' };
    }

    if (containsZeroSizeItems(fileList)) {
      return { key: 'EmptyFoldersAndFilesNotAllowed' };
    }

    if (isFolderDuplicated(fileList, uploads)) {
      return { key: 'UploadFolderAlreadyExists' };
    }

    const maxFileSize = contextData.portalSettings.maximumUploadSize;
    if (doAnyFileExceedAllowedSize(convertFileListToArray(fileList), maxFileSize)) {
      const formattedFileSize = getFormattedFileSize(maxFileSize);
      return { key: 'MaxFileSizeExcess', params: { maxFileSize: formattedFileSize } };
    }

    const maxCctvFolderUploadSize = contextData.portalSettings.maximumCctvFolderUploadSize;
    if (isCctvFolderExceedsMaximumSize(fileList, maxCctvFolderUploadSize)) {
      const formattedCctvFolderSize = getFormattedFileSize(maxCctvFolderUploadSize);
      return { key: 'MaxCctvFolderSizeExcess', params: { maxCctvFolderSize: formattedCctvFolderSize } };
    }

    return null;
  };

  /**
   * Check if any of the files attempting to be added excess alloved size.
   * @param {FileList} filesToAdd Native browser FileList object
   * @param {number} maxFileSize
   * @return {boolean}
   */
  function doAnyFileExceedAllowedSize(filesToAdd, maxFileSize) {
    return filesToAdd.some(function (file) {
      return file.size > maxFileSize;
    });
  }

  /**
   * Checks if any FileList objects are for a folder or a zero length file (0 bytes)
   * @param {FileList} fileList Native browser FileList object
   * @return {boolean}
   * @private
   */
  function containsZeroSizeItems(fileList) {

    for (let i = 0; i < fileList.length; i++) {
      if (!fileList[i].size) {
        return true;
      }
    }

    return false;
  }

  function isFolderDuplicated(fileList, uploads) {
    const filesToAdd = convertFileListToArray(fileList);
    let folderAlreadyExist = false;

    ko.utils.arrayForEach(filesToAdd, fileToAdd => {
      if (fileToAdd.isCctv) {
        const folderName = fileToAdd.webkitRelativePath.substr(0, fileToAdd.webkitRelativePath.indexOf('/'));

        if (uploads.some(existedFile => {
          return existedFile.rootFolderName === folderName;
        })) {
          folderAlreadyExist = true;
        }
      }
    });

    return folderAlreadyExist;
  }

  function isCctvFolderExceedsMaximumSize(fileList, maximumAllowedSize) {
    const filesToAdd = convertFileListToArray(fileList);
    let folderSize = 0;
    for (const fileToAdd of filesToAdd) {
      if (fileToAdd.isCctv) {
        folderSize += fileToAdd.size;
        if (folderSize > maximumAllowedSize) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * @param {FileList} fileList Native browser FileList object
   * @return {Array}
   * @private
   */
  function convertFileListToArray(fileList) {

    let i;
    const files = [];

    for (i = 0; i < fileList.length; i++) {
      files.push(fileList[i]);
    }

    return files;
  }

  // TODO: Need to be refactored into helper
  function getFormattedFileSize(bytes) {

    if (bytes === 1) {
      return '1 Byte';
    }

    if (bytes < 1024) {
      return bytes + ' Bytes';
    }

    if (bytes < 1048576) {
      return roundDownToOneDp((bytes / 1024)) + ' KB';
    }

    if (bytes < 1073741824) {
      return roundDownToOneDp((bytes / 1048576)) + ' MB';
    }

    return roundDownToOneDp((bytes / 1073741824)) + ' GB';
  }

  function roundDownToOneDp(number) {
    number = number * 10;
    number = Math.floor(number);
    number = number / 10;

    return number.toFixed(1);
  }
}

export default new RequestUploadValidation();
