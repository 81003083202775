import ko from 'knockout';
import requestUploadValidation from '@/Validation/requestUploadValidation';
import discussionRepository from '@/Repositories/discussionRepository';
import uploadRepository from '@/Repositories/uploadRepository';
import requestRepository from '@/Repositories/requestRepository';
import logger from '@/Utils/logger';
import redirectHelper from '@/Utils/redirectHelper';
import uploadManager from '@/Utils/uploadManager';
import constants from '@/constants';
import template from '@/Components/dynamicRequestDetails/dynamicRequestDetails.html';
import RequestAssigneeFormatter from '@/Utils/requestAssigneeFormatter';

export function DynamicRequestDetailsViewModel(routeParams) {

  const self = this;

  self.formSaved = ko.observable(false); // Flags if save Ajax request in progress
  self.formSubmitted = ko.observable(false); // Flags if submit Ajax request in progress
  self.formRejected = ko.observable(false); // Flags if reject Ajax request in progress
  self.disableActionButtons = ko.observable(false); // Flags if Ajax buttons for action are disabled

  // Uploads use custom validation but the reason for rejection uses standard knockout validation with
  // the outcome held on the request.rejectionClientErrors property.
  self.clientError = ko.observable(null);
  self.serverErrors = ko.observableArray([]);

  self.request = routeParams.request;
  self.requestFormDefinition = JSON.parse(routeParams.request.dynamicRequestFormDefinition);

  self.requestFormData = routeParams.request.dynamicRequestFormData ?
    JSON.parse(routeParams.request.dynamicRequestFormData) : null;
  self.responseFormData = routeParams.request.dynamicResponseFormData ?
    JSON.parse(routeParams.request.dynamicResponseFormData) : null;

  self.responseFormDefinition = JSON.parse(routeParams.request.dynamicResponseFormDefinition);
  self.responseFormDataChange = null;
  self.responseFormDataChangedCallback = args => {
    self.responseFormDataChange = args.data;
  };
  self.cameraNameObjects = routeParams.cameraNameObjects;

  self.uploads = ko.observableArray().extend({ rateLimit: 500 });

  self.uploadsLoadingError = ko.observable(false);
  self.uploadsLoading = ko.observable(true);
  uploadRepository.getUploadsByRequestId(routeParams.requestId).then(data => {
    self.uploads(data);
  }).catch(() => {
    logger.error('FailedToRetrieveUploads');
    self.uploadsLoadingError(true);
  }).finally(() => {
    self.uploadsLoading(false);
  });

  self.discussionMessages = ko.observableArray(routeParams.discussionMessages);
  self.discussionMessagesCount = ko.pureComputed(() => self.discussionMessages().length);
  self.isReloadingDiscussionMessages = ko.observable(false);

  self.requestAssignees = routeParams.requestAssignees;
  self.selectedRequestAssignee = ko.observable(getSelectedRequestAssignee());
  self.selectedRequestAssignee.subscribe(assignee => {
    self.request.assignedPersonaId = assignee && assignee.assigneeType === constants.requestAssigneeTypes.user ? assignee.id : null;
    self.request.assignedGroupId = assignee && assignee.assigneeType === constants.requestAssigneeTypes.group ? assignee.id : null;
  });

  self.associatedFiles = ko.observableArray(routeParams.associatedFiles);
  self.hasAssociatedFiles = ko.computed(function () {
    return self.associatedFiles() && self.associatedFiles().length > 0;
  }, this);

  self.showSubmitPrompt = ko.observable(false); // Flags if the response summary and T&Cs modal is displayed
  self.showRejectPrompt = ko.observable(false); // Flags if the reject modal is displayed
  self.reasonForRejectionHasFocus = ko.observable(false);

  // Subscribe to upload manager uploads to know when another module cancels an upload
  uploadManager.uploads.subscribe(function (changes) {

    let change;
    let upload;

    for (let i = 0; i < changes.length; i++) {
      change = changes[i];
      upload = change.value;

      if (change.status === 'deleted' && upload.statusName() === constants.uploadStatuses.cancelled) {
        self.uploads.remove(upload);
      }
    }

  }, null, 'arrayChange');

  if (self.request.statusJustChangedToInProgress) {
    logger.info('RequestStatusChangedToInProgress');
  }

  /**
   * Makes a request to the server to send a new discussion message
   * @param {string} message
   * @return {Promise<T>}
   */
  self.sendDiscussionMessage = message => {

    if (!self.request.allowedFeatures.discussionMessaging) {
      logger.error('UnhandledError', 'Discussion messaging is not allowed for this request.');
      return Promise.reject(new Error('Discussion messaging is not allowed for this request.'));
    }

    return discussionRepository.sendMessage(self.request.requestId, message)
        .then(discussionMessages => {
        // The latest version of all the discussion messages are returned so all new ones are displayed
          this.discussionMessages(discussionMessages);
        })
        .catch(jqXhr => {
          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
            throw jqXhr; // Rethrow so this function returns a rejected promise
          }

          if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnhandledError', 'Unable to send the discussion message at this time.', jqXhr);
          }

          throw jqXhr; // Rethrow so this function returns a rejected promise
        });
  };

  /**
   * Makes a request to the server to retrieve the latest discussion messages
   * @return {void}
   */
  self.reloadDiscussionMessages = () => {
    self.isReloadingDiscussionMessages(true);

    discussionRepository.getMessagesByRequestId(self.request.requestId)
        .then(discussionMessages => {
          this.discussionMessages(discussionMessages);
        })
        .catch(jqXhr => {
          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
            return;
          }

          if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnhandledError', 'Unable to retrieve the latest discussion messages at this time.', jqXhr);
          }
        })
        .finally(() => {
          self.isReloadingDiscussionMessages(false);
        });
  };

  self.addFileUploads = fileList => {

    const isUploadValid = self.validateFileUploads(fileList);

    if (!isUploadValid) {
      return;
    }
    self.addUploadsToRepository(fileList);
  };

  self.addUploadsToRepository = fileList => {
    const newUploads = uploadRepository.addUploads(fileList, self.request.requestId, self.addUploadsFailed);
    ko.utils.arrayPushAll(self.uploads, newUploads);
  };

  self.validateFileUploads = fileList => {
    const addUploadsValidationFailureResourceInfo =
      requestUploadValidation.getAddUploadsValidationResourceInfo(fileList, self.uploads());

    if (addUploadsValidationFailureResourceInfo) {
      self.clientError(addUploadsValidationFailureResourceInfo);
      return false;
    }
    self.clientError(null);
    return true;
  };

  self.addUploadsFailed = function (e) {

    if (e.serverErrorMessages) {
      self.serverErrors(e.serverErrorMessages);
      return;
    }

    logger.error('UnhandledError', 'An unexpected error occurred while attempting add file uploads.', e);
  };

  self.save = function () {

    self.serverErrors([]);

    if (self.request.commentsClientErrors().length > 0) {
      self.request.commentsClientErrors.showAllMessages(true);
      return Promise.resolve(null);
    }

    const changedUploads = self.uploads().filter(upload => upload.hasChanged() && upload.uploadId === null);

    if (changedUploads && changedUploads.length) {
      // Some items can be changed while authorising but without upload id yet to prevent the failure of Save operation a user is warned instead
      if (changedUploads.some(upload => !upload.isAuthorising)) {
        // Such situation is not expected. It means that in some reason upload id was completely lost.
        logger.error('UnhandledError');
      } else {
        logger.warning('SaveWhileAuthorising');
      }
      return Promise.resolve(null);
    }

    self.disableActionButtons(true);
    self.formSaved(true);

    const requestData = ko.toJS(self.request);

    return Promise.all([
      self.setSavedDynamicFields(requestData),
      requestRepository.updateRequest(requestData),
      uploadRepository.updateUploads(self.uploads())
    ])
        .then(function () {
          logger.success('RequestUpdatedSummary');

          self.disableActionButtons(false);
          self.formSaved(false);
        })
        .catch(function (jqXhr) {

          self.disableActionButtons(false);
          self.formSaved(false);

          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
          } else if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnhandledError', 'An unexpected error occurred while attempting to update the request.', jqXhr);
          }

          return Promise.reject(jqXhr);
        });
  };

  self.confirmSubmit = function () {

    self.serverErrors([]);

    if (self.request.commentsClientErrors().length > 0) {
      self.request.commentsClientErrors.showAllMessages(true);
      return;
    }

    const statusValidationFailureResourceInfo = requestUploadValidation.getStatusValidationResourceInfo(self.uploads(), true);

    if (statusValidationFailureResourceInfo) {
      self.clientError(statusValidationFailureResourceInfo);
      return;
    }

    self.dynamicForm.validate().then(function (isValid) {
      if (!isValid) {
        self.clientError('DynamicFormHasErrors');
        return;
      }

      self.clientError(null);
      self.showSubmitPrompt(true);
    });
  };

  self.submit = function () {

    self.showSubmitPrompt(false);
    self.disableActionButtons(true);
    self.formSubmitted(true);

    const requestData = getRequestDataWithUploadIds(self.uploads(), self.request);
    self.setSavedDynamicFields(requestData);

    return uploadRepository.updateUploads(self.uploads()).then(function () {
      return requestRepository.updateRequestAndSubmitResponse(requestData);
    })
        .then(function () {
          logger.success('ResponseSubmittedSummary');
          redirectHelper.redirectToHash('#requests');
        })
        .catch(function (jqXhr) {

          self.disableActionButtons(false);
          self.formSubmitted(false);

          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
          } else if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnhandledError', 'An unexpected error occurred while attempting to submit the request.', jqXhr);
          }

          return Promise.reject(jqXhr);
        });
  };

  self.confirmReject = function () {

    const statusValidationFailureResourceInfo = requestUploadValidation
        .getStatusValidationResourceInfo(self.uploads(), true);

    if (statusValidationFailureResourceInfo && statusValidationFailureResourceInfo.key === 'UploadsStillInProgress') {
      self.clientError({ key: 'UploadStillInProgressReject' });
      return;
    }

    self.showRejectPrompt(true);
  };

  self.setReasonForRejectionFocus = function () {
    self.reasonForRejectionHasFocus(true);
  };

  self.reject = function () {

    if (self.request.rejectionClientErrors().length > 0) {
      self.request.rejectionClientErrors.showAllMessages(true);
      return;
    }

    self.showRejectPrompt(false);
    self.disableActionButtons(true);
    self.formRejected(true);

    self.request.requestStatus = constants.requestStatuses.rejected.value;
    self.request.comments(self.request.reasonForRejection());
    const requestData = ko.toJS(self.request);

    requestRepository.updateRequestAndSubmitResponse(requestData)
        .then(() => {
          logger.success('ResponseSubmittedSummary');
          redirectHelper.redirectToHash('#requests');
        }).catch(jqXhr => {

          self.disableActionButtons(false);
          self.formRejected(false);

          if (jqXhr.serverErrorMessages) {
            self.serverErrors(jqXhr.serverErrorMessages);
          } else if (!jqXhr.errorHasBeenLogged) {
            logger.error('UnhandledError', 'An unexpected error occurred while attempting to submit the request.', jqXhr);
          }
          return Promise.reject(jqXhr);
        });
  };

  self.removeUpload = function (upload) {
    if (Array.isArray(upload)) {
      return uploadRepository.deleteUploads(upload)
          .then(() => {
            ko.utils.arrayForEach(upload, function (item) {
              self.uploads.remove(item);
            });
          })
          .catch(function (jqXhr) {
            if (!jqXhr.errorHasBeenLogged) {
              logger.error('UnhandledError', 'Failed to delete the file upload.', jqXhr);
            }

            throw jqXhr;
          });

    } else {
      return uploadRepository.deleteUpload(upload)
          .then(() => {
            self.uploads.remove(upload);
          })
          .catch(function (jqXhr) {

            if (!jqXhr.errorHasBeenLogged) {
              logger.error('UnhandledError', 'Failed to delete the file upload.', jqXhr);
            }

            throw jqXhr;
          });
    }
  };

  /**
   * Convert view model to a standard JS object and include upload Ids property as required for submit
   * @param {Array} uploadsArray
   * @param {Object} request
   * @return{Object}
   */
  function getRequestDataWithUploadIds(uploadsArray, request) {

    let uploadIds = uploadsArray.map(function (upload) {
      return upload.uploadId;
    });

    // Remove the upload Ids where they are null due to an upload failing authorisation
    uploadIds = uploadIds.filter(function (uploadId) {
      return uploadId !== null;
    });

    const requestData = ko.toJS(request);
    requestData.submittedUploadIds = uploadIds;
    return requestData;
  }

  self.setSavedDynamicFields = function (requestDataModel) {
    // Remove data that doesn't need to be submitted
    requestDataModel.dynamicRequestFormDefinition = null;
    requestDataModel.dynamicRequestFormData = null;
    requestDataModel.dynamicResponseFormDefinition = null;
    requestDataModel.dynamicResponseAllowUploads = null;
    requestDataModel.dynamicResponseUploadsIncludeCameraSelection = null;
    requestDataModel.dynamicResponseConfirmationText = null;

    // Set dynamic data for submission if there're any changes
    if (self.responseFormDataChange) {
      requestDataModel.dynamicResponseFormData = JSON.stringify(self.responseFormDataChange);
    }
  };

  self.renderObject = {
    option: (data, escape) => {
      const container = document.createElement('div');
      const templateParameters =
      {
        optionText: escape(data.name),
        iconClass: RequestAssigneeFormatter.getRequestAssigneeIconClass(data),
        title: RequestAssigneeFormatter.getRequestAssigneeTitle(data)
      };
      ko.renderTemplate('option-template', templateParameters, null, container, 'replaceChildren');
      return container.innerHTML;
    }
  };

  function getSelectedRequestAssignee() {
    if (self.request.assignedPersonaId) {
      return self.requestAssignees.find(item => {
        return item.assigneeType === constants.requestAssigneeTypes.user && item.id === self.request.assignedPersonaId;
      }) || {};
    }

    if (self.request.assignedGroupId) {
      return self.requestAssignees.find(item => {
        return item.assigneeType === constants.requestAssigneeTypes.group && item.id === self.request.assignedGroupId;
      }) || {};
    }

    return {};
  }
}

export default { viewModel: DynamicRequestDetailsViewModel, template: template };
