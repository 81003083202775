import ko from 'knockout';
import addressLookupRepository from '../../Repositories/addressLookupRepository';
import logger from '../../Utils/logger';
import template from './addressLookup.html';

export function AddressLookupViewModel(params) {
  const self = this;

  self.showAddressSearchResults = ko.observable(false);
  self.addressSearchTerm = ko.observable('');
  self.addressSearchTerm.focused = ko.observable();
  self.addressSearchTerm.focused.subscribe(function (newValue) {
    if (!newValue) {
      self.showAddressSearchResults(false);
    }
  });
  self.addressSearchResults = ko.observableArray([]);
  self.searching = ko.observable(false);
  self.coordinate = params.coordinate;

  self.lookupAddress = function () {

    if (!self.addressSearchTerm().length) {
      return;
    }

    logger.debug('looking up address for \'%s\'', self.addressSearchTerm());

    self.addressSearchResults.removeAll();
    self.searching(true);
    self.showAddressSearchResults(true);

    addressLookupRepository.lookupAddress(self.addressSearchTerm())
        .then(function (addressSearchResults) {
          self.addressSearchResults(addressSearchResults);

          self.searching(false);

          logger.debug('received %d results', addressSearchResults.length);
        })
        .catch(function (jqXhr) {

          self.showAddressSearchResults(false);

          if (!jqXhr.errorHasBeenLogged) {
            const debugMessage = 'An unexpected error occurred while attempting retrieve address lookup results.';
            logger.error('UnhandledError', debugMessage, jqXhr);
          }
        });
  };

  self.setLocation = function () {
    logger.debug('setting location to %f, %f', this.coordinate.latitude, this.coordinate.longitude);

    self.coordinate({ latitude: this.coordinate.latitude, longitude: this.coordinate.longitude });

    self.showAddressSearchResults(false);
  };

}

// The default export returns the component details object to register with KO
export default { viewModel: AddressLookupViewModel, template: template };
